import React, { useState } from "react";
import Modal from "../../components/Modal";
import "./style.css";

const BeneficiosDetalhesRegulamentos = () => {
  const [beneficioModal, setBeneficioModal] = useState(false);
  const [detalhesModal, setDetalhesModal] = useState(false);
  const [regulamentosModal, setRegulamentosModal] = useState(false);

  return (
    <>
      <Modal header="Benefícios" open={beneficioModal} handleClose={() => setBeneficioModal(!beneficioModal)}>
        <div className="flex flex-col text-[14px]">
          <span>Ligações ilimitadas para todas as operadoras</span>
          <span>SMS ilimitado para todas as operadoras</span>
          <span>Pacote Américas</span>
          <span>Internet Acumulada</span>
        </div>
      </Modal>

      <Modal header="Detalhes" open={detalhesModal} handleClose={() => setDetalhesModal(!detalhesModal)}>
        <div className="flex flex-col text-[14px]">
          <span>Apps inclusos</span>
          <span>Instagram</span>
          <span>Facebook</span>
          <span>Twitter</span>
          <span>Apps inclusos</span>
          <span>Messenger</span>
          <span>WhatsApp</span>
          <span>Outros benefícios</span>
          <span>TIM Segurança Digital Premium</span>
          <span>TIM Nuvem</span>
          <span>Aya Equilibrah</span>
          <span>Audiobooks By Ubook Premium</span>
          <span>Bancah Premium + Jornais</span>
          <span>TIM Music by Deezer</span>
          <span>Aya Ensinah Premium</span>
          <span>Mulheres Positivas</span>
        </div>
      </Modal>

      <Modal
        header="Regulamentos"
        open={regulamentosModal}
        handleClose={() => setRegulamentosModal(!regulamentosModal)}
      >
        <div className="links flex flex-col text-[14px] gap-1">
          <a
            target="_blank"
            href="https://planostim.online/firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents/pos-pago/regulamento/Anexo%201%20-%20Regulamento-oferta-tim-black_A_B_C_HERO.pdf"
          >
            Regulamento do Plano
          </a>
          <a
            target="_blank"
            href="https://planostim.online/firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents/pos-pago/regulamento/Anexo%203%20-%20regulamento_promo_desconto_tim_black__fidelizacao.pdf"
          >
            Regulamento de Fidelização
          </a>
          <a
            target="_blank"
            href="https://planostim.online/firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents/Regulamento%20Oferta%20B%C3%B4nus%20Promo%20de%20internet%2020GB%2001.11.23%20at%C3%A9%2030.11.23%20.pdf"
          >
            Regulamento Bônus 20GB
          </a>
          <a
            target="_blank"
            href="https://planostim.online/firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents/pos-pago/Regulamento%20B%C3%B4nus%20PORTIN%20-%20Promo%20de%20Internet%20Especial%205GB_%2030GB%20-%2031.03.2024%20-.pdf"
          >
            Regulamento Bônus Portabilidade
          </a>
          <a
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/sumario%2Fwww-tim-com-br-para-voce-planos-controle-sumarios-tim-controle-redes-sociais-fim_ok.pdf?alt=media&token=65180512-f52c-4018-a760-51590aee9c69"
          >
            Súmario do plano
          </a>
        </div>
      </Modal>

      <div className="flex w-full flex-col md:flex-row gap-3 md:gap-0 mt-3 md:mt-0 justify-between md:p-5">
        <button
          className="botao"
          onClick={() => {
            setBeneficioModal(true);
          }}
        >
          Benefícios
        </button>
        <button
          className="botao"
          onClick={() => {
            setDetalhesModal(true);
          }}
        >
          Detalhes
        </button>
        <button
          className="botao"
          onClick={() => {
            setRegulamentosModal(true);
          }}
        >
          Regulamentos
        </button>
      </div>
    </>
  );
};

export default BeneficiosDetalhesRegulamentos;
