import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Help from "../../assets/Global/help.png";
import Checkbox from "@mui/material/Checkbox";
import { cinquenta, setenta, setentaCinco, oitentaDeezer, oitenta } from "../../utils/cadastroPlanosBlack";
import "./style.css";
import BeneficiosDetalhesRegulamentos from "../BeneficiosDetalhesRegulamentos";
import PedidoTimBlackDetalhes from "../PedidoTimBlackDetalhes";

import deezerIcon from "../../assets/Planos/TimBlack/icon-deezer.png";

const PriceContainer = ({ info, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <section
      style={{ border: `${info.activated ? "solid #002198 2px" : "solid #F3F3F3 2px"}` }}
      className="bg-white w-full flex-col flex justify-between rounded-[6px] px-2 py-1.5 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex gap-1 items-center w-full border-b-[#E0E0E0] border-b-[1px]">
        <div className="flex flex-col  w-full">
          <span className="text-[13px]">Até</span>
          <div className="flex items-center gap-1 ">
            <span className="mb-[2px] font-extrabold text-[#002198] uppercase md:text-[20px] text-[18px]">
              {info.gigas}
            </span>
            <span> de internet</span>
            <Typography
              className="cursor-help"
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <img src={Help} className="w-[18px]" alt="help icon" />
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1, fontSize: "12px" }}>{info.descricao}</Typography>
            </Popover>
          </div>
        </div>
        <div>
          <div className="flex items-start">
            <Radio
              checked={info.activated}
              sx={{
                color: "#002198",
                "&.Mui-checked": {
                  color: "#002198",
                },
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-1 items-center mt-1">
        <span className="font-bold text-[14px]">R$ {info.fidelizado ? info.preco : info.precoNaoFidelizado}</span>
        <span className="text-[11px]">/mês</span>
      </div>
    </section>
  );
};

const TimBlackComponent = () => {
  const [planos, setPlanos] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedIndexPlan, setSelectedIndexPlan] = useState(1);

  useEffect(() => {
    const arrPlanos = [cinquenta, setenta, setentaCinco, oitentaDeezer, oitenta];
    setPlanos(arrPlanos);
    setSelectedPlan(arrPlanos[1]);
  }, []);

  const goToURL = () => {
    window.location.href = selectedPlan.url;
    console.log(selectedPlan.url);
  };

  // const handleDeezer = (flag) => {
  //   let copySelectedPlan = { ...selectedPlan };
  //   let copyPlanos = [...planos];
  //   copySelectedPlan.deezer = flag;

  //   if (flag) {
  //     const newPreco = ((parseFloat(copySelectedPlan.preco.replace(",", ".")) + 5).toFixed(2) + "").replace(".", ",");
  //     copySelectedPlan.preco = newPreco;

  //     const newPrecoNaoFidelizado = (
  //       (parseFloat(copySelectedPlan.precoNaoFidelizado.replace(",", ".")) + 5).toFixed(2) + ""
  //     ).replace(".", ",");
  //     copySelectedPlan.precoNaoFidelizado = newPrecoNaoFidelizado;
  //   } else {
  //     const newPreco = ((parseFloat(copySelectedPlan.preco.replace(",", ".")) - 5).toFixed(2) + "").replace(".", ",");
  //     copySelectedPlan.preco = newPreco;

  //     const newPrecoNaoFidelizado = (
  //       (parseFloat(copySelectedPlan.precoNaoFidelizado.replace(",", ".")) - 5).toFixed(2) + ""
  //     ).replace(".", ",");
  //     copySelectedPlan.precoNaoFidelizado = newPrecoNaoFidelizado;
  //   }
  //   copyPlanos[selectedIndexPlan - 1].preco = copySelectedPlan.preco;
  //   setPlanos(copyPlanos);
  //   setSelectedPlan(copySelectedPlan);
  // };

  const handleFidelizado = (checked) => {
    let newSelectedPlan = { ...selectedPlan, fidelizado: checked };
    setSelectedPlan(newSelectedPlan);
    let copyPlanos = [...planos];

    if (!checked) {
      copyPlanos[selectedIndexPlan - 1].fidelizado = false;
      console.log(copyPlanos);
    } else {
      copyPlanos[selectedIndexPlan - 1].fidelizado = true;
      console.log(copyPlanos);
    }
    setPlanos(copyPlanos);
  };

  const changeActivated = (index) => {
    setPlanos((prevPlanos) =>
      prevPlanos.map((plano, i) => ({
        ...plano,
        activated: i === index,
      }))
    );
    setSelectedIndexPlan(planos[index].index);
    setSelectedPlan(planos[index]);
  };

  return (
    <section className="flex w-full justify-center text-black py-[30px]">
      <div className="md:max-w-[990px] px-8 md:px-0 flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <span className="font-normal">Planos TIM Black</span>
          <span className="text-[22px] font-light">
            Escolha a melhor opção do TIM<br></br> Black para você e o seu negócio
          </span>
        </div>

        <div className="w-full flex md:flex-row flex-col gap-4 md:gap-0 justify-between">
          {planos.length > 0 && (
            <>
              <div className="md:w-[50%] w-full md:pr-4">
                <div className="flex flex-col gap-4">
                  {planos.map((plano, index) => (
                    <PriceContainer key={index} info={plano} onClick={() => changeActivated(index)} />
                  ))}
                </div>
              </div>
              {/* START: Lado direito */}
              <div className=" md:w-[50%] w-full md:px-2">
                <div className="border-[#002198] bg-white border-[2px] rounded-[6px] flex flex-col p-7 gap-6">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <b className="text-[14px] mb-[3px]">Plano TIM Black</b>
                      <div className="flex items-end gap-1.5">
                        <span className="text-[14px]">Até</span>
                        <span className="font-extrabold text-black uppercase md:text-[16px]">
                          {selectedPlan.gigas} de internet
                        </span>
                      </div>
                      <span className="text-[12px]">{selectedPlan.descricao}</span>
                    </div>
                    {selectedPlan.melhorOferta && (
                      <div>
                        <div className="rounded-full bg-[#F2CB08] px-4 flex items-center py-1">
                          <span className="text-[11px] font-medium">Melhor oferta</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <span className="text-[14px] font-medium">Ligações e SMS Ilimitados</span>
                  </div>

                  <div className="flex flex-col">
                    <b>{selectedPlan.app1text}</b>
                    <img className="w-[120px] mb-[14px]" src={selectedPlan.app1img} />
                    {selectedPlan.deezerObrigatorio && (
                      <div className="flex flex-row items-center gap-[10px]">
                        <img src={deezerIcon} alt="Icone Deezer" className="w-[34px] h-[34px]" />
                        <span className="text-[14px] font-medium">Assinatura Deezer Premium inclusa</span>
                      </div>
                    )}
                    <b>{selectedPlan.app2text}</b>
                    <img className="w-[75px]" src={selectedPlan.app2img} />
                  </div>

                  {selectedPlan.escolherAssinaturas && (
                    <div className="flex flex-col gap-3">
                      <span className="text-[14px]">Liberdade em escolher a assinatura entre:</span>
                      <div className="flex flex-col gap-4">
                        {selectedPlan.escolherAssinaturas.length > 1 ? (
                          selectedPlan.escolherAssinaturas.map((assinatura) => (
                            <div className="flex gap-4 items-center">
                              <img src={assinatura.img} className="w-[38px]" />
                              <span className="text-[14px]">{assinatura.desc}</span>
                            </div>
                          ))
                        ) : (
                          <img src={selectedPlan.escolherAssinaturas[0].img} className="w-[70%]" />
                        )}
                      </div>
                    </div>
                  )}

                  <div className="flex justify-between items-center border-[#E0E0E0] py-2 border-y-[1px]">
                    {selectedPlan.cartaoCredito ? (
                      <span>No cartão de crédito</span>
                    ) : (
                      <>
                        <b>{selectedPlan.fidelizado ? "Fidelizado por 12 meses" : "Sem permanência mínima"}</b>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={selectedPlan.fidelizado}
                                onChange={(e) => {
                                  handleFidelizado(e.target.checked);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{
                                  marginRight: "-15px",

                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#6EC709",
                                  },
                                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: "#498F52",
                                  },
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="font-normal mb-[-5px]">Por apenas:</span>
                      <div>
                        <b className="text-[25px]">
                          R$ {selectedPlan.fidelizado ? selectedPlan.preco : selectedPlan.precoNaoFidelizado}
                        </b>
                        <span> /mês</span>
                      </div>
                      <span className="text-[12px] mt-[-4px]">Por 12 meses</span>
                    </div>
                    <div className="flex justify-end">
                      <button
                        className="bg-[#002198] text-[15px] w-full font-bold px-[45px] py-[12px]"
                        style={{ borderRadius: "12px", color: "white" }}
                        onClick={() => goToURL()}
                      >
                        Contratar
                      </button>
                    </div>
                  </div>
                </div>
                <BeneficiosDetalhesRegulamentos />
              </div>
              {/* END: Lado direito */}
            </>
          )}
        </div>
        <PedidoTimBlackDetalhes info={selectedPlan} />
      </div>
    </section>
  );
};

export default TimBlackComponent;
