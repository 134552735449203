import React from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import "./style.css";

const BotaoWhatsappFlutuante = () => {
  return (
    <section>
      <a
        href="https://wa.me/558196716091" // Substitua pelo seu número do WhatsApp
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiOutlineWhatsApp className="whatsapp_icon" />
      </a>
    </section>
  );
};

export default BotaoWhatsappFlutuante;
