import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlineRightCircle } from "react-icons/ai";
import parse from "html-react-parser";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  borderBottom: `1px solid white`,
  "&::before": {
    display: "none",
  },
  width: "100%",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "0.9rem",
          transform: "rotate(90deg)",
          color: props.typeColor === "azul" ? "white" : "black",
        }}
      />
    }
  />
))(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-180deg)",
  },
  padding: "0 8px", // Reduzindo o padding
  minHeight: "48px", // Definindo altura mínima
  "& .MuiAccordionSummary-content": {
    margin: "8px 0", // Reduzindo a margem
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "8px 16px", // Reduzindo o padding
}));

export default function CustomizedAccordions({ headerText, someWidth, elements, noShow, type, typeColor }) {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div
      style={{
        backgroundColor: typeColor === "azul" ? "#072198" : "white",
        color: typeColor === "azul" ? "white" : "black",
      }}
      className="flex flex-col justify-end items-center w-full"
    >
      <div className="w-full md:px-0 px-4">
        <div style={{ borderBottom: `1px solid white` }}>
          <strong style={{ color: "white" }} className="text-uppercase">
            {headerText?.toUpperCase()}
          </strong>
        </div>
        {elements.map((item, index) => (
          <Accordion
            key={index}
            className="w-full"
            style={{ width: `${someWidth ? someWidth : "100%"}`,backgroundColor: typeColor === "azul" ? "#072198" : "white" }}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
              typeColor={typeColor} // Passando a prop para styled component
            >
              <Typography
                className="py-2"
                style={{ fontFamily: "TIM Sans", color: typeColor === "azul" ? "white" : "black" }}
              >
                {typeColor === "white" ? (
                  <span className="text-[15px]">{item.title}</span>
                ) : (
                  <strong className="text-[15px]">{item.title}</strong>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ fontFamily: "TIM Sans", color: typeColor === "azul" ? "white" : "black" }}>
                <span>{parse(item.text)}</span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        {!noShow && (
          <>
            
              <div className="flex my-5 text-white text-[14px] items-center">
                <a
                  target="_blank"
                  href="https://planostim.online/firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents/Regulamento%20B%C3%B4nus%20C6%20-%20TIM%20Controle%2031.03.2023.pdf"
                >
                  <button className="flex gap-2 hover:bg-[#1532A1] px-2 py-2 rounded-[7px]">
                    Consultar todas as perguntas
                    <AiOutlineRightCircle className="text-[18px]" />
                  </button>
                </a>
              </div>
            
          </>
        )}
      </div>
    </div>
  );
}
