import vinteCincoFidelizadoImage from "../assets/Planos/Controle/25Fidelizado.png";
import faceInstaXWhats from "../assets/Planos/TimBlack/faceInstaXWhats.jpeg"
import iconAppleTv from "../assets/Planos/TimBlack/icon-apple-tv.webp"
import iconChefsClub from "../assets/Planos/TimBlack/icon-chefs-club.png"
import iconDeezer from "../assets/Planos/TimBlack/icon-deezer.png"
import iconDisneyPlus from "../assets/Planos/TimBlack/icon-disney-plus.png"
import geralJunto from '../assets/Planos/TimBlack/geralJunto.jpeg'
import appsblack from '../assets/Planos/TimBlack/appsblack.jpeg'
// import vinteCincoFidelizadoImage from "../assets/Planos/Controle/25Fidelizado.png";

export const cinquenta = {
  // Página Cadastro
  index: 1,
  nome: "TIM Black C Light 5.0",
  gigas: "50GB",
  franquia: "20GB",
  descricaoCard: "20GB Franquia + 15GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank", 
  descricao: "Até 50GB de Internet = 20GB Franquia + 15GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank",
  appsInclusos: appsblack,
  preco: "105,99",
  precoNaoFidelizado: "135,99",
  fidelizado: true,
  mensagemDeezer: "Oferta turbinada com Deezer",
  mensagemDeezerOff: "Turbine sua oferta com Deezer",
  deezerObrigatorio: true,
  deezer: true,
  bonusc6: "5GB",
  franquia: "15GB",
  infoLigacoes:
    "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Pacote américas",
  // Página normal
  melhorOferta: true,
  activated: false,
  cartao: false,
  app1text: "Apps inclusos",
  app1img: faceInstaXWhats,
  url: "/cadastro/blackclight",
}

export const setenta = {
  // Página Cadastro
  index: 2,
  nome: "TIM Black A 5.0",
  gigas: "70GB",
  franquia: "15GB",
  descricaoCard: "15GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank", 
  descricao: "Até 70GB de Internet = 15GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank",
  appsInclusos: appsblack,
  preco: "119,99",
  precoNaoFidelizado: "179,99",
  fidelizado: true,
  mensagemDeezer: "Oferta turbinada com Deezer",
  mensagemDeezerOff: "Turbine sua oferta com Deezer",
  deezerObrigatorio: true,
  deezer: true,
  infoLigacoes:
    "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Pacote américas",
  // Página normal
  melhorOferta: true,
  activated: true,
  cartao: false,
  app1text: "Apps inclusos",
  app1img: faceInstaXWhats,
  url: "/cadastro/blacka",
}

export const setentaCinco =  {
   // Página Cadastro
   index: 3,
   nome: "TIM Black B 5.0",
   gigas: "75GB",
   franquia: "20GB",
   descricao: "Até 75GB de Internet = 20GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank",
   appsInclusos: appsblack,
   preco: "139,99",
   precoNaoFidelizado: "194,99",
   fidelizado: true,
   mensagemDeezer: "Oferta turbinada com Deezer",
   mensagemDeezerOff: "Turbine sua oferta com Deezer",
   deezerObrigatorio: false,
   deezer: false,
   infoLigacoes:
   "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Pacote américas",
   // Página normal
   descricaoCard: "20GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank", 
   melhorOferta: false,
   activated: false,
   cartao: false,
   app1text: "Apps inclusos",
   app1img: faceInstaXWhats,
   escolherAssinaturas: [
    {img: iconDisneyPlus, desc: "Assinatura Disney Plus"},
    {img: iconDeezer, desc: "Assinatura Deezer Premium"}
   ],
   url: "/cadastro/blackb",
}

export const oitentaDeezer = {
  // Página Cadastro
  index: 4,
  nome: "TIM Black C 5.0",
  gigas: "80GB",
  franquia: "25GB",
  descricao: "Até 80GB de Internet = 25GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank",
  appsInclusos: appsblack,
  preco: "159,99",
  precoNaoFidelizado: "234,99",
  fidelizado: true,
  mensagemDeezer: "Oferta turbinada com Deezer",
  mensagemDeezerOff: "Turbine sua oferta com Deezer",
  deezerObrigatorio: true,
  deezer: true,
  infoLigacoes:
  "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Pacote américas",
  // Página normal
  descricaoCard: "25GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank", 
  melhorOferta: false,
  activated: false,
  cartao: false,
  app1text: "Apps inclusos",
  app1img: faceInstaXWhats,
  escolherAssinaturas: [
    {img: geralJunto}
  ],
  url: "/cadastro/blackc",
}

export const oitenta =  {
   // Página Cadastro
   index: 5,
   nome: "TIM Black C 5.0 Ultra",
   gigas: "80GB",
   franquia: "25GB",
   descricao: "Até 80GB de Internet = 25GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank",
   appsInclusos: appsblack,
   preco: "159,99",
   precoNaoFidelizado: "234,99",
   fidelizado: true,
   mensagemDeezer: "Oferta turbinada com Deezer",
   mensagemDeezerOff: "Turbine sua oferta com Deezer",
   deezer: true,
   infoLigacoes:
   "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Pacote américas",
   // Página normal
   descricaoCard: "25GB Franquia + 40GB bônus garantido + 10GB bônus Extra trazendo seu número p/ TIM + 5GB bônus Extra C6 Bank", 
   melhorOferta: false,
   activated: false,
   cartao: false,
   app1text: "Apps inclusos",
   app1img: faceInstaXWhats,
   escolherAssinaturas: [
    {img: iconAppleTv, desc: "Assinatura Apple Tv"},
    {img: iconChefsClub, desc: "Assinatura Chefs Club"},
  ],
   url: "/cadastro/blackcultra",
}