import { useEffect, useState } from "react";
import "./style.css";
import mulherFone from "../../assets/TimBlack/mulher-fone.png";

const MulherFoneBlack = () => {
  return (
    <section className="bg-[#002198] w-full justify-center flex py-5 ">
      <div className="md:max-w-[990px] md:px-0">
        <div className="w-full flex flex-col bg-[#002198]">
          <div className="flex flex-col-reverse items-center md:gap-0 gap-5 md:flex-row md:justify-between pt-10">
            <div className="flex flex-col gap-5 text-white justify-between px-6">
              <span className="font-regular text-[14px] text-center md:text-start">Mais que um Pós-pago</span>
              <span className="text-[26px] md:text-[32px] md:leading-[44px] leading-[30px] text-center md:text-start">
                Música ilimitada em qualquer lugar com o Deezer Premium
              </span>

              <div className="flex flex-col gap-2">
                <strong className="text-[14px] text-center md:text-start">Recomendações personalizadas</strong>
                <span className="text-[14px] text-center md:text-start">Ouça suas músicas favoritas ou um mix de opções inéditas</span>
              </div>

              <div className="flex flex-col gap-2">
                <strong className="text-[14px] text-center md:text-start">Sem descontar da internet</strong>
                <span className="text-[14px] text-center md:text-start">
                  Mais de 73 milhões de músicas disponíveis para você escutar à vontade.
                </span>
              </div>

            </div>
            <img src={mulherFone} alt="" className="w-[50%]" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MulherFoneBlack;
