import todosInclusos from "../assets/Planos/Controle/inclusos.png";

export const planosBlackEmpresas = [
  {
    // Página Cadastro
    index: 7,
    melhorOferta: true,
    activated: true,
    gigas: "30GB",
    franquia: "10GB",
    descricao: "10GB Franquia + 10GB de bônus garantido + 10GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "57,99",
    deezer: true,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 30GB + Deezer",
    oferta: "100GB",
    bonus: "10GB",
    bonusPortabilidade: "0",
    c6: "10GB",
    portabilidade: false,

    url: "/cadastro/timempresa30deezer/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 8,
    melhorOferta: false,
    activated: false,
    gigas: "55GB",
    franquia: "20GB",
    descricao: "20GB Franquia + 5GB de bônus de portabilidade + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "50,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 55GB",
    exclusivoPortabilidade: true,
    oferta: "20GB",
    bonus: "0",
    bonusPortabilidade: "5GB",
    c6: "30GB",
    portabilidade: true,

    url: "/cadastro/blackempresa55portabilidade/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 1,
    melhorOferta: false,
    activated: false,
    gigas: "8GB",
    franquia: "4GB",
    descricao: "4GB Franquia + 2GB de bônus garantido + 2GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "39,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 8GB",
    oferta: "4GB",
    bonus: "2GB",
    bonusPortabilidade: "0GB",
    c6: "2GB",
    portabilidade: false,

    url: "/cadastro/timempresa8/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 2,
    melhorOferta: false,
    activated: false,
    gigas: "30GB",
    franquia: "10GB",
    descricao: "10GB Franquia + 10GB de bônus garantido + 10GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "52,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 30GB",
    oferta: "10GB",
    bonus: "10GB",
    bonusPortabilidade: "0GB",
    c6: "10GB",
    portabilidade: false,

    url: "/cadastro/timempresa30/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 8,
    melhorOferta: false,
    activated: false,
    gigas: "55GB",
    franquia: "20GB",
    descricao: "20GB Franquia + 5GB de bônus de portabilidade + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "50,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 55GB",
    oferta: "20GB",
    bonus: "0",
    bonusPortabilidade: "5GB",
    c6: "30GB",
    portabilidade: true,

    url: "/cadastro/timempresa55portabilidade/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 3,
    melhorOferta: false,
    activated: false,
    gigas: "60GB",
    franquia: "20GB",
    descricao: "20GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "64,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 60GB",
    oferta: "20GB",
    bonus: "10GB",
    bonusPortabilidade: "0GB",
    c6: "30GB",
    portabilidade: false,

    url: "/cadastro/timempresa60/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 9,
    melhorOferta: false,
    activated: false,
    gigas: "60GB",
    franquia: "20GB",
    descricao: "20GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "69,99",
    deezer: true,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 60GB + Deezer",
    oferta: "20GB",
    bonus: "10GB",
    bonusPortabilidade: "0",
    c6: "30GB",

    url: "/cadastro/timempresa60deezer/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 4,
    melhorOferta: false,
    activated: false,
    gigas: "70GB",
    franquia: "30GB",
    descricao: "30GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "69,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 70GB",
    oferta: "30GB",
    bonus: "10GB",
    bonusPortabilidade: "0GB",
    c6: "30GB",
    portabilidade: false,

    url: "/cadastro/timempresa70/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  ,
  {
    // Página Cadastro
    index: 10,
    melhorOferta: false,
    activated: false,
    gigas: "70GB",
    franquia: "30GB",
    descricao: "30GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "74,99",
    deezer: true,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 70GB + Deezer",
    oferta: "30GB",
    bonus: "10GB",
    bonusPortabilidade: "0",
    c6: "30GB",

    url: "/cadastro/timempresa70deezer/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 5,
    melhorOferta: false,
    activated: false,
    gigas: "90GB",
    franquia: "50GB",
    descricao: "50GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "119,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nnome: "TIM Black Empresa 90GB",
    oferta: "50GB",
    bonus: "10GB",
    bonusPortabilidade: "0",
    c6: "30GB",
    portabilidade: false,

    url: "/cadastro/timempresa90/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 11,
    melhorOferta: false,
    activated: false,
    gigas: "90GB",
    franquia: "50GB",
    descricao: "50GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "124,99",
    deezer: true,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 90GB + Deezer",
    oferta: "50GB",
    bonus: "10GB",
    bonusPortabilidade: "0",
    c6: "30GB",

    url: "/cadastro/timempresa90deezer/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 6,
    melhorOferta: false,
    activated: false,
    gigas: "140GB",
    franquia: "100GB",
    descricao: "100GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "169,99",
    deezer: false,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 140GB",
    oferta: "100GB",
    bonus: "10GB",
    bonusPortabilidade: "0",
    c6: "30GB",
    portabilidade: false,

    url: "/cadastro/timempresa140/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
  {
    // Página Cadastro
    index: 12,
    melhorOferta: false,
    activated: false,
    gigas: "140GB",
    franquia: "100GB",
    descricao: "100GB Franquia + 10GB de bônus garantido + 30GB abrindo conta e pagando a fatura pelo app do C6 Bank",
    appsInclusos: todosInclusos,
    preco: "174,99",
    deezer: true,
    infoLigacoes:
      "Ligações Ilimitadas para todas as operadoras \n SMS Ilimitado para todas as operadoras \n Roaming nacional",
    // Página normal
    app1text: "Apps inclusos",
    app1img: todosInclusos,
    nome: "TIM Black Empresa 140GB + Deezer",
    oferta: "100GB",
    bonus: "10GB",
    bonusPortabilidade: "0",
    c6: "30GB",

    url: "/cadastro/timempresa140deezer/", // aqui acho que vamos precisar mudar pra uma url comum a todos /cadastro/tim-black-empresa...
  },
];
