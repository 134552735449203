export const estados = [
    { index: 1, nome: "Acre", ddd: 68 },
    { index: 2, nome: "Alagoas", ddd: 82 },
    { index: 3, nome: "Amazonas", ddd: [92, 97] },
    { index: 4, nome: "Bahia", ddd: [71, 73, 74, 75, 77] },
    { index: 5, nome: "Ceará", ddd: [85, 88] },
    { index: 6, nome: "Distrito Federal", ddd: 61 },
    { index: 7, nome: "Espírito Santo", ddd: [27, 28] },
    { index: 8, nome: "Goiás", ddd: [62, 64] },
    { index: 9, nome: "Maranhão", ddd: [98, 99] },
    { index: 10, nome: "Minas Gerais", ddd: [31, 32, 33, 34, 35, 37, 38] },
    { index: 11, nome: "Mato Grosso do Sul", ddd: 67 },
    { index: 12, nome: "Mato Grosso", ddd: [65, 66] },
    { index: 13, nome: "Pará", ddd: [91, 93, 94] },
    { index: 14, nome: "Paraíba", ddd: 83 },
    { index: 15, nome: "Pernambuco", ddd: [81, 87] },
    { index: 16, nome: "Piauí", ddd: [86, 89] },
    { index: 17, nome: "Paraná", ddd: [41, 42, 43, 44, 45, 46] },
    { index: 18, nome: "Rio de Janeiro", ddd: [21, 22, 24] },
    { index: 19, nome: "Rio Grande do Norte", ddd: 84 },
    { index: 20, nome: "Rondônia", ddd: 69 },
    { index: 21, nome: "Rio Grande do Sul", ddd: [51, 53, 54, 55] },
    { index: 22, nome: "Santa Catarina", ddd: [47, 48, 49] },
    { index: 23, nome: "Sergipe", ddd: 79 },
    { index: 24, nome: "São Paulo", ddd: [11, 12, 13, 14, 15, 16, 17, 18, 19] },
    { index: 25, nome: "Tocantins", ddd: 63 },
  ];

export const vencimentos = [
  {index: 1, nome: "01"},
  {index: 2, nome: "07"},
  {index: 3, nome: "10"},
  {index: 4, nome: "12 (Melhor data de pagamento)"},
  {index: 5, nome: "15"},
  {index: 6, nome: "20"}
]

export const tiposConta = [
  {index: 1, nome: "Digital (E-mail)"},
  {index: 2, nome: "Correios (Boleto)"},
]

export const bancos = [
  {index: 1, nome: "001 - Banco do Brasil"},
  {index: 2, nome: "033 - Santander"},
  {index: 3, nome: "037 - Banpará"},
  {index: 4, nome: "084 - Uniprime"},
  {index: 5, nome: "237 - Bradesco"},
  {index: 6, nome: "341 - Itaú"}
]

export const operadoras = [
  { index: 1, nome: "Claro" },
  { index: 2, nome: "Vivo" },
  { index: 3, nome: "TIM" },
  { index: 5, nome: "Outra" },
];
