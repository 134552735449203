import { useState } from "react";
import esimTim from "../../assets/Global/esimTim.png";
import logo from "../../assets/Global/revendedor-autorizado-tim.png";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import PrimeiraEtapa from "../../assets/Esim/primeira-etapa.webp";
import parse from 'html-react-parser';

import androidCodigo1 from '../../assets/Esim/android-codigo/androidCodigo1.webp';
import androidCodigo2 from '../../assets/Esim/android-codigo/androidCodigo2.webp';
import androidCodigo3 from '../../assets/Esim/android-codigo/androidCodigo3.webp';
import androidCodigo4 from '../../assets/Esim/android-codigo/androidCodigo4.webp';
import androidCodigo5 from '../../assets/Esim/android-codigo/androidCodigo5.webp';
import androidCodigo6 from '../../assets/Esim/android-codigo/androidCodigo6.webp';

import androidQr1 from '../../assets/Esim/android-qr/androidQr1.png'
import androidQr2 from '../../assets/Esim/android-qr/androidQr2.png'
import androidQr3 from '../../assets/Esim/android-qr/androidQr3.png'
import androidQr4 from '../../assets/Esim/android-qr/androidQr4.png'

import motorolaCodigo1 from '../../assets/Esim/motorola-codigo/motorolaCodigo1.webp';
import motorolaCodigo2 from '../../assets/Esim/motorola-codigo/motorolaCodigo2.webp';
import motorolaCodigo3 from '../../assets/Esim/motorola-codigo/motorolaCodigo3.webp';
import motorolaCodigo4 from '../../assets/Esim/motorola-codigo/motorolaCodigo4.webp';
import motorolaCodigo5 from '../../assets/Esim/motorola-codigo/motorolaCodigo5.webp';
import motorolaCodigo6 from '../../assets/Esim/motorola-codigo/motorolaCodigo6.webp';

import motorolaQr1 from '../../assets/Esim/motorola-qr/motorolaQr1.png';
import motorolaQr2 from '../../assets/Esim/motorola-qr/motorolaQr2.png';
import motorolaQr3 from '../../assets/Esim/motorola-qr/motorolaQr3.png';
import motorolaQr4 from '../../assets/Esim/motorola-qr/motorolaQr4.png';

import appleCodigo1 from '../../assets/Esim/apple-codigo/appleCodigo1.webp';
import appleCodigo2 from '../../assets/Esim/apple-codigo/appleCodigo2.webp';
import appleCodigo3 from '../../assets/Esim/apple-codigo/appleCodigo3.webp';
import appleCodigo4 from '../../assets/Esim/apple-codigo/appleCodigo4.webp';
import appleCodigo5 from '../../assets/Esim/apple-codigo/appleCodigo5.webp';
import appleCodigo6 from '../../assets/Esim/apple-codigo/appleCodigo6.webp';

import appleQr1 from '../../assets/Esim/apple-qr/appleQr1.png';
import appleQr2 from '../../assets/Esim/apple-qr/appleQr2.png';
import appleQr3 from '../../assets/Esim/apple-qr/appleQr3.png';
import appleQr4 from '../../assets/Esim/apple-qr/appleQr4.png';
import appleQr5 from '../../assets/Esim/apple-qr/appleQr5.png';





import "./style.css";

const instrucaoAparelhoModo = [
  { aparelho: 1, modo: 1, etapas: [
    { numero: 1, texto: 'Acesse <b>"Configurações"</b> e toque em <b>"Conexões"</b>', img: androidCodigo1 },
    { numero: 2, texto: `Ao acessar, toque em <b>"Gerenciador de chip"</b>`, img: androidCodigo2 },
    { numero: 3, texto: 'Em seguida, toque em <b>"Adicionar eSim"</b>', img: androidCodigo3 },
    { numero: 4, texto: `Para inserir manualmente, toque em <b>"Escanear o código QR da operadora"</b>`, img: androidCodigo4 },
    { numero: 5, texto: `Para inserir manualmente, toque em <b>"Insira o código de ativação"</b>`, img: androidCodigo5 },
    { numero: 6, texto: `Insira o código de ativação e toque em <b>"Conectar"</b>`, img: androidCodigo6 },
  ]},
  {aparelho: 1,
    modo: 2,
    etapas: [
      { numero: 1, texto: 'Acesse <b>"Conexões" "Gerenciamento de cartão SIM"</b>', img: androidQr1 },
      { numero: 2, texto: 'Acesse a opção <b>"Adicionar plano móvel"</b> utilizando o método código QR', img: androidQr2 },
      { numero: 3, texto: 'Faça a leitura do <b>QR Code</b> aguarde a ativação', img: androidQr3 },
      { numero: 4, texto: 'Receberá mensagem de confirmação para ativação da rede TIM"</b>', img: androidQr4 }
    ]},
    {aparelho: 2,
      modo: 1,
      etapas: [
        { numero: 1, texto: 'Acesse <b>"Configurações"</b> e toque em <b>"Rede e Internet"</b>', img: motorolaCodigo1 },
        { numero: 2, texto: 'Ao acessar, toque em <b>"Rede Móvel"</b>', img: motorolaCodigo2 },
        { numero: 3, texto: 'Toque em <b>"Adicionar um número usando o eSIM"</b> e em seguida toque em <b>"Próxima"</b>', img: motorolaCodigo3 },
        { numero: 4, texto: 'Para inserir os dados manualmente, toque em <b>"Preciso de ajuda"</b>', img: motorolaCodigo4 },
        { numero: 5, texto: 'No texto exibido, toque em <b>"Insira o código manualmente"</b>', img: motorolaCodigo5 },
        { numero: 6, texto: 'Toque em <b>"Insira o código manualmente"</b>', img: motorolaCodigo6 }
      ]},
    {
      aparelho: 2,
      modo: 2,
      etapas: [
        { numero: 1, texto: 'Entre nas <b>"configurações do aparelho"</b>, <b>"Rede e Internet"</b>, <b>"Rede móvel"</b>, <b>"Avançadas"</b>', img: motorolaQr1 },
        { numero: 2, texto: '<b>"Operadora"</b> e <b>"Adicionar operadora"</b> o celular irá começar a fazer o download das informações do chip', img: motorolaQr2 },
        { numero: 3, texto: 'Após o download será necessário escanear o <b>QR CODE</b> disponibilizado no ato da compra do chip. Após isso a TIM irá adicionar o número automaticamente', img: motorolaQr3 },
        { numero: 4, texto: 'Pronto! Seu número foi adicionado e você já pode começar a utilizar seu aparelho', img: motorolaQr4 }
      ]},
      {
        aparelho: 3,
        modo: 1,
        etapas: [
          { numero: 1, texto: 'Acesse <b>"Ajustes"</b> e toque em <b>"Celular"</b> ou <b>"Dados Móveis"</b>', img: appleCodigo1 },
          { numero: 2, texto: 'Toque em <b>"Adicionar plano celular"</b>', img: appleCodigo2 },
          { numero: 3, texto: 'Toque em <b>"Inserir Detalhes Manualmente"</b> na parte inferior da tela do iPhone', img: appleCodigo3 },
          { numero: 4, texto: 'Insira os dados do código de ativação', img: appleCodigo4 },
          { numero: 5, texto: 'Identifique os planos, caso existam outros ativos', img: appleCodigo5 },
          { numero: 6, texto: 'Defina o número padrão que será usado no celular', img: appleCodigo6 }
        ],
      },
      {
        aparelho: 3,
        modo: 2,
        etapas: [
          { numero: 1, texto: 'Acesse <b>"Ajustes"</b> e toque em <b>"Celular"</b> ou <b>"Dados Móveis"</b>', img: appleQr1 },
          { numero: 2, texto: 'Toque em <b>"Adicionar plano celular"</b>', img: appleQr2 },
          { numero: 3, texto: 'Escaneie o código QR fornecido pela TIM', img: appleQr3 },
          { numero: 4, texto: 'Identifique os planos', img: appleQr4 },
          { numero: 5, texto: 'Defina o número padrão que será usado no celular', img: appleQr5 }
        ],
      },
      
      
]

const EsimComoAtivar = () => {
  const [aparelho, setAparelho] = useState("");
  const [modo, setModo] = useState("");

  return (
    <div className="flex flex-col w-full">
      <section className="bg-[#002198] w-full flex justify-center">
        <div className="md:min-w-[990px] flex flex-col gap-3 md:gap-0">
          <div className="flex justify-start py-4">
            <a href="/">
              <img className="w-[50px]" src={logo} />
            </a>
          </div>
          <div className="flex  flex-col justify-center items-center pb-3 pt-10 gap-2">
            <img className="w-[80px]" src={esimTim}></img>
            <span className="text-white font-medium text-[19px]">Veja como ativar o seu e-chip</span>
          </div>
        </div>
      </section>

      <section style={{minHeight: `${modo === "" ? "70vh" : ""}`}} className="bg-white w-full flex justify-center">
        <div className="md:min-w-[990px] flex flex-col">
          <div className="w-full flex items-center py-3 flex-col gap-3 md:gap-0">
            <span className="font-medium">Selecione o sistema do seu aparelho</span>
            <Box sx={{ minWidth: "80%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Clique para selecionar</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={aparelho}
                  onChange={(e) => {
                    setAparelho(e.target.value);
                  }}
                  style={{ backgroundColor: "white" }}
                >
                  <MenuItem value={1}>Smartphones Samsung Android 10.0 ou posterior</MenuItem>
                  <MenuItem value={2}>Motorola Razr Android Pie 9.0 ou posterior</MenuItem>
                  <MenuItem value={3}>iPhone iOS 13.3.1 ou posterior</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          {aparelho !== "" && (
            <div className="w-full flex items-center py-3 flex-col gap-3 md:gap-0">
              <span className="font-medium">Selecione como gostaria de instalar seu e-chip</span>
              <Box sx={{ minWidth: "80%" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Clique para selecionar</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={modo}
                    onChange={(e) => {
                      setModo(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                  >
                    <MenuItem value={1}>Via código de ativação</MenuItem>
                    <MenuItem value={2}>Via QR code</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}
          <div>
            <span className="mt-[-8px] text-[13px] w-full md:px-0 px-4 gap-1 flex flex-col md:flex-row justify-center items-center text-[#777777] pb-2">
              <b>Atenção!</b>
              <span className="text-center">
                Antes de iniciar o procedimento certifique-se que o Smartphone está conectado à rede WI-FI.
              </span>
            </span>
          </div>
        </div>
      </section>

      {modo !== "" && (
        <section className="bg-white w-full flex justify-center pt-10 md:px-0 px-4">
          <div className="md:max-w-[990px] w-full flex flex-col">
            <div className="flex justify-center">
              <div className="w-[30%] hidden md:flex">
                <hr role="separator" aria-orientation="horizontal" className="custom-hr" />
              </div>
              <div className="md:w-[40%] w-full flex justify-center pb-8">
                <span className="font-medium text-center">Smartphones Samsung Android 10.0 ou posterior:</span>
              </div>
              <div className="w-[30%] hidden md:flex">
                <hr role="separator" aria-orientation="horizontal" className="custom-hr" />
              </div>
            </div>

            <div className="flex flex-col gap-12">
              {instrucaoAparelhoModo.find((e) => (e.aparelho == aparelho && e.modo == modo)).etapas.map((etapa) => (
                <div className="flex justify-between">
                  <div className="flex flex-col gap-2">
                    <div className="rounded-full border-[3px] text-[#0326A5] border-[#0326A5] w-[40px] flex justify-center items-center">
                      <b className="text-[20px]">{etapa.numero}</b>
                    </div>
                    <div className="font-normal max-w-[300px] text-[16px]">{parse(etapa.texto)}</div>
                  </div>

                  <div className="flex justify-end">
                    <img src={etapa.img} className="w-[100%]" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      <section className="w-full flex justify-center pt-16 pb-3">
        <button
          onClick={() => {
            window.location.href = "/esim";
          }}
          className="bg-[#2F48AC] text-[white] px-5 rounded-[10px] py-1.5 text-[13px] font-bold"
        >
          Adquirir meu plano com eSIM
        </button>
      </section>

      <section className="bg-[#002198] w-full flex justify-center py-1.5">
        <img className="w-[50px]" src={logo} />
      </section>
    </div>
  );
};

export default EsimComoAtivar;
