import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import Principal from "./pages/Principal/index.jsx";
import Esim from "./pages/Esim/index.jsx";
import TimBlack from "./pages/TimBlack/index.jsx";
import Cadastro from "./pages/Cadastro/index.jsx";

import { planosBlackEmpresas } from './utils/cadastroPlanosBlackEmpresas.js'

import { vinteCincoFidelizado, vinteCincoCartao, trintaDoisCartao, trintaDoisFidelizado } from "./utils/cadastroPlanosControle";
import { cinquenta, setenta, setentaCinco, oitenta, oitentaDeezer } from "./utils/cadastroPlanosBlack.js";
import EsimComoAtivar from "./pages/EsimComoAtivar/index.jsx";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Principal />} />
      <Route path="/controle/:esim?" element={<Principal />} />
      <Route path="/esim" element={<Esim />} />
      <Route path="/tim-black/:esim?" element={<TimBlack />} />
      {planosBlackEmpresas.map((e) => 
        <Route path={e.url} element={<Cadastro planoInfo={e} />} />
      )}
      {/* <Route path="/cadastro/controlesmart" element={<Cadastro tipoPlano="controle" planoInfo={vinteCincoFidelizado} />} />
      <Route path="/cadastro/controleexpressj" element={<Cadastro tipoPlano="controle" planoInfo={vinteCincoCartao} />} />
      <Route path="/cadastro/controleredes" element={<Cadastro tipoPlano="controle" planoInfo={trintaDoisFidelizado}/>} />
      <Route path="/cadastro/controleexpressl" element={<Cadastro tipoPlano="controle" planoInfo={trintaDoisCartao} />} />
      <Route path="/cadastro/blackclight" element={<Cadastro planoInfo={cinquenta} />} />
      <Route path="/cadastro/blackcultra" element={<Cadastro planoInfo={oitenta} />} />
      <Route path="/cadastro/blacka" element={<Cadastro planoInfo={setenta} />} />
      <Route path="/cadastro/blackb" element={<Cadastro planoInfo={setentaCinco} />} />
      <Route path="/cadastro/blackc" element={<Cadastro planoInfo={oitentaDeezer} />} /> */}

      <Route path="/esim/como-ativar-seu-esim" element={<EsimComoAtivar />} />
    </Routes>
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
