import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Help from "../../assets/Global/help.png";
import Checkbox from "@mui/material/Checkbox";
import deezerIcon from "../../assets/Planos/TimBlack/icon-deezer.png";
import {
  vinteCincoFidelizado,
  trintaDoisFidelizado,
  vinteCincoCartao,
  trintaDoisCartao,
} from "../../utils/cadastroPlanosControle";
import "./style.css";
import BeneficiosDetalhesRegulamentos from "../BeneficiosDetalhesRegulamentos";
import DetalhesPedido from "../DetalhesPedido";
import { useParams } from "react-router-dom";
import { planosBlackEmpresas } from "../../utils/cadastroPlanosBlackEmpresas.js";

const PriceContainer = ({ info, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    console.log(info);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="z-10 flex gap-2">
        {info.melhorOferta && (
          <div className="rounded-full bg-[#F2CB08] px-3 flex items-center py-1 w-[100px] mb-[-15px] ml-[2px]">
            <span className="text-[11px] font-medium">Melhor oferta</span>
          </div>
        )}
        {info.exclusivoPortabilidade && (
          <div className="rounded-full bg-[#99D7FF] px-3 flex items-center py-1 w-[145px] mb-[-15px] ml-[2px]">
            <span className="text-[11px] font-medium">Exclusivo portabilidade</span>
          </div>
        )}
      </div>
      <div
        style={{ border: `${info.activated ? "solid #002198 2px" : "solid #F3F3F3 2px"}` }}
        className="bg-white w-full flex flex-col justify-between rounded-[6px] px-2 py-4 cursor-pointer"
        onClick={onClick}
      >
        <section className="flex justify-between items-center">
          <div className="flex gap-1 items-center">
            <div className="flex items-end gap-1.5">
              <span className="text-[13px]">Até</span>
              <span className="mb-[-2px] font-extrabold text-[#002198] uppercase md:text-[20px] text-[18px]">
                {info.gigas} de internet
              </span>
            </div>

            <Typography
              className="cursor-help"
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <img src={Help} className="w-[18px]" alt="help icon" />
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1, fontSize: "12px" }}>{info.descricao}</Typography>
            </Popover>
          </div>

          <div>
            <span className="font-bold text-[15px]">R$ {info.preco}</span>
          </div>

          <div className="flex items-start mt-[-10px]">
            <Radio
              checked={info.activated}
              sx={{
                color: "#002198",
                "&.Mui-checked": {
                  color: "#002198",
                },
              }}
            />
          </div>
        </section>
        <section className="flex justify-between w-full">
          <div className="flex gap-2 text-[14px]">
            {info.deezer && (
              <div className="flex gap-2">
                <b>+</b>
                <span>Deezer Premium incluso</span>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

const TimControle = () => {
  const [planos, setPlanos] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedIndexPlan, setSelectedIndexPlan] = useState(1);
  let { esim } = useParams();

  useEffect(() => {
    setPlanos(planosBlackEmpresas);
    setSelectedPlan(planosBlackEmpresas[0]);
  }, []);

  const goToURL = () => {
    window.location.href = selectedPlan.url;
    console.log(selectedPlan.url);
  };

  const handleDeezer = (flag) => {
    let copySelectedPlan = { ...selectedPlan };
    let copyPlanos = [...planos];
    copySelectedPlan.deezer = flag;

    if (flag) {
      const newPreco = ((parseFloat(copySelectedPlan.preco.replace(",", ".")) + 5).toFixed(2) + "").replace(".", ",");
      copySelectedPlan.preco = newPreco;

      const newPrecoNaoFidelizado = (
        (parseFloat(copySelectedPlan.precoNaoFidelizado.replace(",", ".")) + 5).toFixed(2) + ""
      ).replace(".", ",");
      copySelectedPlan.precoNaoFidelizado = newPrecoNaoFidelizado;
    } else {
      const newPreco = ((parseFloat(copySelectedPlan.preco.replace(",", ".")) - 5).toFixed(2) + "").replace(".", ",");
      copySelectedPlan.preco = newPreco;

      const newPrecoNaoFidelizado = (
        (parseFloat(copySelectedPlan.precoNaoFidelizado.replace(",", ".")) - 5).toFixed(2) + ""
      ).replace(".", ",");
      copySelectedPlan.precoNaoFidelizado = newPrecoNaoFidelizado;
    }
    copyPlanos[selectedIndexPlan - 1].preco = copySelectedPlan.preco;
    setPlanos(copyPlanos);
    setSelectedPlan(copySelectedPlan);
  };

  const handleFidelizado = (checked) => {
    let newSelectedPlan = { ...selectedPlan, fidelizado: checked };
    setSelectedPlan(newSelectedPlan);
    let copyPlanos = [...planos];

    if (!checked) {
      copyPlanos[selectedIndexPlan - 1].fidelizado = false;
      console.log(copyPlanos);
    } else {
      copyPlanos[selectedIndexPlan - 1].fidelizado = true;
      console.log(copyPlanos);
    }
    setPlanos(copyPlanos);
  };

  const changeActivated = (index) => {
    setPlanos((prevPlanos) =>
      prevPlanos.map((plano, i) => ({
        ...plano,
        activated: i === index,
      }))
    );
    setSelectedIndexPlan(planos[index].index);
    setSelectedPlan(planos[index]);

    if (window.innerWidth <= 768) {
      const element = document.getElementById("info-geral-plano");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  return (
    <section className="flex w-full justify-center text-black py-[30px]" id="planos-info">
      <div className="md:min-w-[990px] min-w-full px-2 md:px-0 flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <span className="font-bold">TIM EMPRESAS</span>
          <span className="text-[14px]">Escolha a melhor opção TIM Empresas para você.</span>
        </div>

        <div className="w-full flex md:flex-row flex-col justify-between gap-4 md:gap-0">
          {planos.length > 0 && (
            <>
              <div className="md:w-[50%] w-full ">
                <div className="flex flex-col gap-4 vertical-container">
                  {planos.map((plano, index) => (
                    <PriceContainer key={index} info={plano} onClick={() => changeActivated(index)} />
                  ))}
                </div>
                <div id="info-geral-plano"></div>
              </div>
              {/* START: Lado direito */}
              <div className=" md:w-[50%] w-full md:px-2">
                <div className="border-[#002198] bg-white border-[2px] rounded-[6px] flex flex-col p-7 gap-6 md:max-w-[600px]">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <b className="text-[14px] mb-[3px] text-[#002198]">TIM Black Empresa</b>
                      <div className="flex items-end gap-1.5">
                        <span className="text-[14px]">Até</span>
                        <span className="font-extrabold text-black uppercase md:text-[16px]">
                          {selectedPlan.gigas} de internet
                        </span>
                      </div>
                      <span className="text-[12px]">{selectedPlan.descricao}</span>
                    </div>
                    {selectedPlan.melhorOferta && (
                      <div>
                        <div className="rounded-full bg-[#F2CB08] px-4 flex items-center py-1">
                          <span className="text-[11px] font-medium">Melhor oferta</span>
                        </div>
                      </div>
                    )}
                    {selectedPlan.exclusivoPortabilidade && (
                      <div>
                        <div className="rounded-full bg-[#99D7FF] px-4 flex items-center py-1 justify-center">
                          <span className="text-[11px] font-medium text-center">Portabilidade</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <b>Ligações</b>
                    <span className="text-[13px] font-light">Ilimitadas para todas as operadoras</span>
                  </div>

                  <div className="flex flex-col">
                    <b>{selectedPlan.app1text}</b>
                    <img className="w-[140px] mb-[14px] ml-[-10px]" src={selectedPlan.app1img} />
                    {/* <b>{selectedPlan.app2text}</b>
                    <img className="w-[75px]" src={selectedPlan.app2img} /> */}
                    {selectedPlan.deezer && (
                      <div className="flex flex-row items-center gap-[10px]">
                        <img src={deezerIcon} alt="Icone Deezer" className="w-[34px] h-[34px]" />
                        <span className="text-[14px] font-medium">Assinatura Deezer Premium inclusa</span>
                      </div>
                    )}
                  </div>

                  {selectedPlan.portabilidade && (
                    <div className="flex justify-between items-center border-[#E0E0E0] py-4 border-y-[1px]">
                      <b>Exclusivo Portabilidade</b>
                    </div>
                  )}

                  <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="font-normal mb-[-5px]">Por apenas:</span>
                      <div>
                        <b className="md:text-[25px] text-[22px]">R$ {selectedPlan.preco}</b>
                        <span> /mês</span>
                      </div>
                      <span className="text-[12px] mt-[-4px]">Por 12 meses</span>
                    </div>
                    <div className="flex justify-end">
                      <button
                        className="bg-[#002198] text-[15px] w-full font-bold px-[20px] md:px-[45px] py-[12px]"
                        style={{ borderRadius: "12px", color: "white" }}
                        onClick={() => goToURL()}
                      >
                        Contratar
                      </button>
                    </div>
                  </div>
                </div>
                <BeneficiosDetalhesRegulamentos />
              </div>
              {/* END: Lado direito */}
            </>
          )}
        </div>
        <DetalhesPedido info={selectedPlan} />
      </div>
    </section>
  );
};

export default TimControle;
