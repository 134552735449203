import { useEffect, useState } from "react";
import Funcionalidade1 from "../../assets/Esim/funcionalidades-esim-1.png";
import Funcionalidade2 from "../../assets/Esim/funcionalidades-esim-2.png";
import Funcionalidade3 from "../../assets/Esim/funcionalidades-esim-3.png";
import "./style.css";

const FuncionalidadesEsim = () => {
  return (
    <section className="bg-white w-full justify-center flex py-5">
      <div className="md:max-w-[990px] md:px-0 px-4 ">
        <div className="flex flex-col items-center mb-8">
          <b>Funcionalidades do TIM eSIM</b>
          <span>Sua nova tecnologia permite:</span>
        </div>

        <div className="flex gap-12 w-full flex-col md:flex-row px-14 md:px-0">
          <div className="flex flex-col md:flex-row gap-5 md:w-[33%]">
            <div className="flex flex-col gap-6 items-center">
              <img src={Funcionalidade1} className="w-[100%]" />
              <b className="text-[15px]">O que é o eSIM?</b>
              <span>É um chip virtual que substitui o chip físico, trazendo mais segurança e confiabilidade.</span>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-5 md:w-[33%] w-full">
            <div className="flex flex-col gap-6 items-center">
              <img src={Funcionalidade2} className="w-[100%]" />
              <b className="text-[15px]">Funcionalidades</b>
              <span>Você pode ter até 8 chips eSIM e usar dois números de telefone ao mesmo tempo. </span>
              <span>Dispositivos diferentes podem compartilhar o mesmo número de celular e plano de internet.</span>
              <span>Em caso de perda ou roubo, o aparalho permanece conectado à internet, podendo ser localizado.</span>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-5 md:w-[33%]">
            <div className="flex flex-col gap-6 items-center">
              <img src={Funcionalidade3} className="w-[100%]" />
              <b className="text-[15px]">Como faço para ter um eSIM?</b>
              <span>É necessário ter um aparelho compatível com a tecnologia.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FuncionalidadesEsim;
