import React from "react";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import "./style.css";
import TimBlackBanner from "../../components/TimBlackBanner";
import TimBlackComponent from "../../components/TimBlackComponent";
import MulherFoneBlack from "../../components/mulherDeFone";
import OutrosServicosTimBlack from "../../components/OutrosServicosTimBlack";
import AbraUmaContaC6 from "../../components/AbraUmaContaC6";
import Acordeon from "../../components/Acordeon";

const PerguntasFrequentes = [
  {
    title: " Quais os benefícios do plano pós-pago TIM Black?",
    text: `<p>O plano pós-pago da TIM, o TIM Black, tem muitos benefícios, como:</p><br>
        <ul>
          <li>Assinatura de streaming inclusa: troque entre HBO Max, YouTube Premium e Disney+ ou aproveite 12 meses de Amazon Prime e Disney+;</li><br>
          <li>Internet acumulada: acumule a internet que você não usa para o mês seguinte (com exceção das ofertas TIM Black A 5.0 e TIM Black A Express 5.1);</li><br>
          <li>Roaming Internacional Américas: navegue com até 25GB durante viagens a países das Américas;</li><br>
          <li>Deezer Premium incluso: ouça mais de 90 milhões de músicas sem gastar internet;</li><br>
          <li>Apps inclusos: use Whatsapp, Instagram, Facebook e Twitter sem descontar internet;</li><br>
          <li>Desconto em smartphones: aproveite preços exclusivos em aparelhos;</li><br>
          <li>Atendimento Concierge (para pagamento em fatura): tenha um atendimento VIP e exclusivo;</li><br>
          <li>Tech Store: aproveite ofertas em produtos inteligentes para você e sua casa.</li><br>
        </ul>`,
  },
  {
    title: " Quais ligações estão incluídas no pacote de minutos do pós-pago TIM Black?",
    text: `Com o plano pós-pago TIM Black, você pode usar o 041 para ligar ilimitado para qualquer operadora do Brasil sem pagar a mais por isso.`,
  },
  {
    title: "É possível cancelar algum benefício do plano pós-pago TIM individualmente? ",
    text: `Não. Os benefícios do plano pós-pago TIM Black estão inclusos nos pacotes das ofertas e não é possível cancelar individualmente.`,
  },
  {
    title: "Quantas linhas ativas posso ter no smartphone?",
    text: `Com exceção da oferta TIM Black A Express 5.1, você aproveita o benefício da internet acumulada no TIM Black Express. Isso significa dizer que a internet não usada é acumulada para o mês seguinte. Assim, você pode ter até o dobro de internet para navegar como quiser no seu plano pós-pago!`,
  },
  {
    title: "A quais streamings tenho acesso ao contratar o TIM Black? ",
    text: `Os streamings inclusos no pós-pago TIM Black variam de acordo com a oferta contratada.`,
  },
  {
    title: "No plano pós-pago, vou pagar somente a assinatura mensal da oferta TIM Black Express e mais nada?  ",
    text: `Sim, a menos que você escolha contratar algum serviço de forma avulsa. Nesse caso, além do valor da sua oferta, também é cobrado o valor adicional na sua conta TIM.`,
  },
];

const TimBlack = () => {
  return (
    <div className="flex flex-col bg-[#FAFAFA]">
      <Menu cor1="#002E47" cor2="#002E46" />
      <TimBlackBanner />
      <TimBlackComponent />
      <MulherFoneBlack />
      <OutrosServicosTimBlack />
      <AbraUmaContaC6 />
      <section className="bg-white w-full justify-center flex md:py-24 py-10">
        <div className="md:max-w-[990px] md:px-0 px-4">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:w-[30%] py-5 md:py-0 mb-[20px]">
              <span className="text-center md:text-start">Tire suas dúvidas</span>
              <span className="text-[26px] md:text-[32px] md:leading-[44px] leading-[30px] text-center md:text-start">
                Sobre o que você quer saber?
              </span>
            </div>
            <div className="md:w-[70%]">
              <b className="md:ml-2 flex justify-center md:justify-start">Principais dúvidas</b>
              <Acordeon typeColor="white" noShow elements={PerguntasFrequentes} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TimBlack;
