import { useEffect, useState } from "react";
import Mulher from "../../assets/Principal/Mulher.png";
import DeezerPremium from "../../assets/Principal/deezerPremium.png";
import Whatsapp from "../../assets/Principal/whatsapp.png";
import X from "../../assets/Principal/x.png";
import Instagram from "../../assets/Principal/instagram.png";
import Messager from "../../assets/Principal/messeger.png";
import Facebook from "../../assets/Principal/facebook.png";

import "./style.css";

const RestanteTimControle = () => {
  return (
    <>
      <section className="bg-black w-full justify-between flex flex-col md:flex-row text-white">
        <div className="md:w-[50%]">
          <img src={Mulher} className="w-[100%]"></img>
        </div>
        <div className="md:w-[35%] bg-black py-20 px-[80px] flex flex-col gap-6">
          <div className="flex justify-between">
            <b>TIM EMPRESAS</b>
            <img src={DeezerPremium} className="w-[130px]" />
          </div>
          <span className="text-[24px] font-bold leading-[25px]">Turbine seu Plano com Deezer Premium e curta</span>
          <ul className="list-disc pl-5 text-[13px]">
            <li>milhares de músicas;</li>
            <li>sem interrupção de propaganda;</li>
            <li>possibilidade de baixar as músicas e ouvir off-line;</li>
            <li>pulos de músicas ilimitados...</li>
          </ul>
          <span>E muito mais!</span>
        </div>
        <div className="w-[15%]"></div>
      </section>

      <section className="bg-[#FAFAFA] w-full justify-center md:flex py-5 px-4 md:px-0">
        <div className="md:min-w-[990px] md:px-0 px-4 flex flex-col gap-5">
          <div className="flex flex-col">
            <b>TIM EMPRESAS</b>
            <span className="text-[14px]">Conecte-se como quiser.</span>
          </div>
          <div className="flex md:flex-row flex-col justify-between gap-5">
            <div className="card-restante">
              <img src={Facebook} className="w-[50px]" />
              <span>Facebook</span>
            </div>
            <div className="card-restante">
              <img src={Instagram} className="w-[50px]" />
              <span>Instagram</span>
            </div>
            <div className="card-restante">
              <img src={X} className="w-[50px]" />
              <span>Twitter</span>
            </div>
            <div className="card-restante">
              <img src={Messager} className="w-[50px]" />
              <span>Messenger</span>
            </div>
            <div className="card-restante">
              <img src={Whatsapp} className="w-[50px]" />
              <span>Whatsapp</span>
            </div>
          </div>
          <div className="flex justify-center mt-10">
            <button
              className="bg-[#002198] font-bold hover:bg-[#1532A1] text-white rounded-[7px] px-[80px] py-2 text-[14px]"
              onClick={() => {
                const element = document.getElementById("planos-info");
                if (element) {
                  element.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              }}
            >
              Contratar
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default RestanteTimControle;
