import React from "react";
import "./style.css";

const DetalhesPedido = ({ info }) => {
  return (
    <section className="flex w-full justify-center text-black">
      {info && (
        <div className="md:min-w-[990px] min-w-full px-1 md:px-0">
          <span className="text-[18px] mb-16"> Detalhes do pedido </span>
          <div className="mt-6 bg-white border-[#F3F3F3] border-2 rounded-[6px]">
            <div className="w-full flex flex-col py-2 px-4">
              <div className="flex md:flex-row flex-col justify-between py-6 detalhesRow">
                <span>Franquia:</span>
                <strong>{info.franquia} de Internet</strong>
              </div>
              <div className="flex md:flex-row flex-col justify-between py-6 detalhesRow">
                <span>Bônus:</span>
                <strong>{info.bonus} de Internet</strong>
              </div>
              <div className="flex md:flex-row flex-col justify-between py-6 detalhesRow">
                <span>Bônus Portabilidade:</span>
                <strong>{info.bonusPortabilidade} </strong>
              </div>
              <div className="flex md:flex-row flex-col justify-between py-6 detalhesRow">
                <span>C6:</span>
                <strong>{info.c6} de Internet </strong>
              </div>
              <div className="flex md:flex-row flex-col justify-between py-6 detalhesRow">
                <span>Ligações:</span>
                <strong>Ilimitadas para todas as operadoras </strong>
              </div>
              <div className="flex md:flex-row flex-col justify-between py-6">
                <span>Valor do plano:</span>
                {/* <strong>R$ {info.fidelizado ? info.preco : info.precoNaoFidelizado}</strong> */}
                <strong>R$ {info.preco}</strong>
              </div>
            </div>
          </div>
          <div className="flex justify-end pt-10 ">
            <button
              className="bg-[#002198] w-full font-bold md:w-[275px] h-[48px]"
              style={{ borderRadius: "12px", color: "white" }}
              onClick={() => {
                window.location.href = info.url;
              }}
            >
              Contratar
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default DetalhesPedido;
