import React from "react";
import gigatim from "../../assets/Principal/gigatim.png";
import wtim from "../../assets/Principal/wtim.png";

import pricetimNovo from "../../assets/Principal/pricetim2.png";
import "./style.css";

const PrincipalBanner = () => {
  return (
    <div className="flex flex-col md:flex justify-center items-center min-h-[35%] pb-0 bg-gradient-to-b from-[#080FBE] to-[#010640]">
      <div className="desktop w-[990px] flex flex-col md:flex-row justify-between items-center">
        <div>
          <img src={gigatim} width="280px" alt="logo" />
        </div>
        <div>
          <img src={wtim} width="240px" alt="logo" />
        </div>
        <a href="/cadastro/timempresa30deezer/">
          <img src={pricetimNovo} width="260px" alt="logo" />
        </a>
      </div>
      {/* START: MOBILE */}
      <div className="py-10 mobile flex gap-10 flex-col justify-between items-center">
        <div className="flex items-center w-full justify-center">
          <a href="/cadastro/timempresa30deezer/" className="z-10">
            <img src={pricetimNovo} width="280px" alt="logo" />
          </a>
          {/* <div className="w-[40%]">
            <img className="" src={wtim} width="100%" alt="logo" />
          </div> */}
        </div>
        <div>
          <img src={gigatim} width="275px" alt="logo" />
        </div>
      </div>
      {/* END: MOBILE */}
    </div>
  );
};

export default PrincipalBanner;
