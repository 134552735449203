import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import eSim from "../../assets/Global/esimTim.png";
import deezerIcon from "../../assets/Planos/TimBlack/icon-deezer.png";
import whatsAppIcon from "../../assets/Planos/Empresas/ofertas-black-whatsapp.png";
import wazeIcon from "../../assets/Planos/Empresas/ofertas-black-waze.png";
import messengerIcon from "../../assets/Planos/Empresas/ofertas-black-messenger.png";
import instagramIcon from "../../assets/Planos/Empresas/ofertas-black-instagram.png";
import faceIcon from "../../assets/Planos/Empresas/ofertas-black-facebook.png";
import xIcon from "../../assets/Planos/Empresas/logo-x-redondo.webp";
import { AiOutlineCheck } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  borderRadius: "10px",
  boxShadow: 24,
  "@media (max-width: 768px)": {
    width: "90%", // telas móveis
  },
};

const CadastroPlanoInfo = ({ type, info, planoEsim, hidePlanoInfo }) => {
  const [fidelizado, setFidelizado] = useState(info.fidelizado);
  const [deezer, setDeezer] = useState(info.deezer);
  const [receber, setReceber] = useState(true);
  const [preco, setPreco] = useState(info.preco);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (deezer) {
      const newPreco = (parseFloat(preco?.replace(",", ".")) + 5).toFixed(2) + "";
      const precoVirgula = newPreco?.replace(".", ",");
      setPreco(precoVirgula);
    } else {
      setPreco(info.preco);
    }
  }, [deezer]);

  useEffect(() => {
    if (info.cartaoCredito) {
      setPreco(info.preco);
      return;
    }
    setPreco(fidelizado ? info.preco : info.precoNaoFidelizado);
  }, [fidelizado]);

  return (
    <>
      {!hidePlanoInfo && (
        <section
          className="w-full text-white flex-col"
          style={{ backgroundColor: `${type === "controle" ? "#002198" : "#002198"}` }}
        >
          <div className="border-[#6877AE] border-b-[1.9px] px-8 pt-4 pb-3">
            <span>Plano selecionado:</span>
          </div>
          <div className="px-8 pt-4 pb-3 flex flex-col gap-4">
            <div className="flex flex-col text-white">
              <span className="mb-1">{info.nome}</span>
              <span className="text-[13px] mb-[-18px]">Até</span>
              <span className="text-[45px] font-bold">{info.gigas}</span>
              <span className="text-white text-[13px] font-normal">{info.descricao}</span>
            </div>
            <div className="flex flex-col">
              {info.deezer && (
                <div className="flex flex-row items-center gap-[10px] mb-[14px]">
                  <img src={deezerIcon} alt="Icone Deezer" className="w-[34px] h-[34px]" />
                  <span className="text-[14px] font-medium">Assinatura Deezer Premium inclusa</span>
                </div>
              )}
              <span className="text-[14px] font-bold">Apps inclusos</span>
              <img src={info.appsInclusos} className="w-[140px] ml-[-10px]" alt="App Inclusos" />
            </div>

            {info?.portabilidade && (
              <div className="flex justify-between items-center border-[#E0E0E0] mt-3 py-4 border-y-[1px]">
                <b>Exclusivo Portabilidade</b>
              </div>
            )}

            <div>
              <span className="text-[30px] font-bold">R$ {preco}</span>
              <span className="text-[30px] font-bold">{info.preco}</span>
              <span className="text-[16px] font-normal"> / mês</span>
              {planoEsim && <img className="mt-5" src={eSim} width="100px" alt="App Inclusos" />}
            </div>
          </div>

          <div className="px-8 pt-4 pb-3 flex flex-col">
            <span className="text-[13px]">{info.infoLigacoes}</span>
          </div>
          <div className="flex items-center px-8 pt-4 pb-3">
            <Checkbox
              style={{ marginLeft: "-10px" }}
              checked={receber}
              onChange={(e) => setReceber(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
                "& .MuiSvgIcon-root": {
                  borderRadius: 1,
                },
              }}
            />
            <span className="ml-[-5px] text-[14px]">Aceito receber contato via sms/rcs/e-mail</span>
          </div>
          <div className="flex items-center px-8 pt-4 pb-3">
            <button
              className="bg-[#ffffff] w-full font-bold md:w-[325px] h-[36px] mb-5"
              style={{ borderRadius: "12px", color: "blue" }}
              onClick={handleOpen}
            >
              Mais informações
            </button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div>
                  <div className="bg-[#002198] p-5 text-white flex flex-col gap-2 rounded-t-[12px]">
                    <button
                      onClick={handleClose}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        color: "white",
                        fontSize: "24px",
                      }}
                    >
                      <IoMdClose />
                    </button>
                    <div className="flex flex-col md:max-w-[50%]">
                      <b className="text-[16px] mb-[8px]">TIM Black Empresa</b>
                      <div className="flex items-end gap-1.5">
                        <span className="text-[16px]">Até</span>
                        <span className="font-extrabold uppercase md:text-[21px]">{info.gigas} de internet</span>
                      </div>
                      <span className="text-[15px]">{info.descricao}</span>
                    </div>
                    <div>
                      <span className="text-[35px] font-bold">R$ {preco}</span>
                      <span className="text-[35px] font-bold">{info.preco}</span>
                      <span className="text-[16px] font-normal"> / mês</span>
                      {planoEsim && <img className="mt-5" src={eSim} width="100px" alt="App Inclusos" />}
                    </div>
                  </div>
                  <div className="p-5 flex flex-col gap-4 bg-white rounded-b-[12px]">
                    <div>
                      <b className="text-[19px] mb-[8px]">Apps para navegar ilimitado:</b>
                      <div className="flex gap-2">
                        <img src={deezerIcon} className="w-[20px]" alt="imagem"></img>
                        <img src={whatsAppIcon} className="w-[20px]" alt="imagem"></img>
                        <img src={instagramIcon} className="w-[20px]" alt="imagem"></img>
                        <img src={faceIcon} className="w-[20px]" alt="imagem"></img>
                        <img src={messengerIcon} className="w-[20px]" alt="imagem"></img>
                        <img src={wazeIcon} className="w-[20px]" alt="imagem"></img>
                        <img src={xIcon} className="w-[20px]" alt="imagem"></img>
                      </div>
                    </div>

                    <div>
                      <b className="text-[19px] mb-[8px]">Incluso no seu plano:</b>
                      <ul>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} /> 800 SMS para qualquer operadora
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          Roaming Nacional sem custo
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          Aya Books by Ubook Premium
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          TIM Nuvem 2T
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          Babbel
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          Bancah Premium
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          TIM Monitor
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          Clube + Vantagens
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          TIM Segurança
                        </li>
                        <li className="flex items-center gap-1">
                          <AiOutlineCheck style={{ color: "green" }} />
                          Deezer incluso
                        </li>
                      </ul>
                    </div>

                    <span className="mt-4 text-[14px] text-[#a9a9a9]">* Permanência mínima de 24 meses.</span>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </section>
      )}
    </>
  );
};

export default CadastroPlanoInfo;
