import { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import "./style.css";

const modelos = {
  1: ["iPhone SE","iPhone SE 2º e 3º Geração","iPhone X","iPhone XR","iPhone XS","iPhone XS Max","iPhone 11","iPhone 11 Pro","iPhone 11 Pro Max","iPhone 12","iPhone 12 Mini","iPhone 12 Pro","iPhone 12 Pro Max","iPhone 13","iPhone 13 Mini","iPhone 13 Pro","iPhone 13 Pro Max","iPhone 14","iPhone 14 Plus","iPhone 14 Pro","iPhone 14 Pro Max","iPhone 15","iPhone 15 Plus","iPhone 15 Pro","iPhone 15 Pro Max","Não encontrei meu modelo"],
  2: ["Galaxy Z Fold 3","Galaxy Z Fold 4","Galaxy Z Fold 5","Galaxy Z Flip","Galaxy Z Flip 3","Galaxy Z Flip 4","Galaxy Z Flip 5","Galaxy S20","Galaxy S20 Plus","Galaxy S20 Ultra","Galaxy S21","Galaxy S21 Plus","Galaxy S21 Ultra","Galaxy S22","Galaxy S22 Plus","Galaxy S22 Ultra","Galaxy S23","Galaxy S23 Plus","Galaxy S23 Ultra","Galaxy S23 FE","Galaxy S24","Galaxy S24 Plus","Galaxy S24 Ultra","Galaxy Note 20","Galaxy Note 20 Ultra","Não encontrei meu modelo"],
  3: ["Razr","Razr 40","Razr 40 Ultra","Edge 40","Edge 40 NEO","Moto G53","Moto G54 5G","Moto G84 5G","Não encontrei meu modelo"],
  4: [], // Vazio
};

const PedidoEsim = () => {
  const [plano, setPlano] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [mostrarErro, setMostrarErro] = useState(false);
  const [habilitarContinuar, setHabilitarContinuar] = useState(false);

  useEffect(() => {
    if (marca !== 4) {
      setMostrarErro(false);
      if (plano !== "" && modelo !== "") {
        setHabilitarContinuar(true);
      }
    } else {
      setMostrarErro(true);
      setHabilitarContinuar(false);
    }
    if (modelo === "Não encontrei meu modelo") {
      setMostrarErro(true);
      setHabilitarContinuar(false);
    }
  }, [plano, marca, modelo]);

  const continuar = () => {
    if (plano == 1){
      window.location.href = "/controle/esim";
    }else {
      window.location.href = "/tim-black/esim";
    }
  };

  return (
    <section className="bg-[#EEF4F6] w-full justify-center flex py-5">
      <div className="md:min-w-[990px] md:px-0 px-4">
        <div className="flex flex-col gap-3">
          <b>Vamos começar o pedido do seu eSIM</b>

          <div className="flex flex-col gap-3">
            <span className="text-[15px]">Selecione qual o plano você gostaria de contratar.</span>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Selecione o plano</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={plano}
                  onChange={(e) => {
                    setPlano(e.target.value);
                  }}
                  style={{ backgroundColor: "white" }}
                >
                  <MenuItem value={1}>TIM Empresas</MenuItem>
                  {/* <MenuItem value={2}>TIM Black</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="flex flex-col gap-3">
            <span className="text-[15px]">
              Selecione a marca do seu aparelho para checar se o modelo é compatível com o eSIM.
            </span>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Selecione a marca</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={marca}
                  onChange={(e) => {
                    setMarca(e.target.value);
                    setModelo(""); // Resetar modelo ao alterar marca
                  }}
                  style={{ backgroundColor: "white" }}
                >
                  <MenuItem value={1}>Apple</MenuItem>
                  <MenuItem value={2}>Samsung</MenuItem>
                  <MenuItem value={3}>Motorola</MenuItem>
                  <MenuItem value={4}>Outras marcas</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          {marca !== "" && marca !== 4 && (
            <div className="flex flex-col gap-3">
              <span className="text-[15px]">
                Selecione o modelo para prosseguir. Caso ele não esteja nessa lista, o pedido não poderá ser feito.
              </span>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Selecione o modelo</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={modelo}
                    onChange={(e) => {
                      setModelo(e.target.value);
                    }}
                    style={{ backgroundColor: "white" }}
                  >
                    {modelos[marca].map((modelo, index) => (
                      <MenuItem key={index} value={modelo}>
                        {modelo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}

          { mostrarErro && (
            <div className="bg-[#FF5252] rounded-[4px] flex justify-center p-2 text-[14px]">
              Não será possível seguir com o seu pedido. Essa modalidade só está disponível para as marcas listadas.
            </div>
          )}

          <button
            style={{
              backgroundColor: `${habilitarContinuar ? "#002198" : "#EEEEEE"}`,
              color: `${habilitarContinuar ? "white" : "text-[#1D1D1D]"}`,
            }}
            className="w-full py-3 rounded-[4px] text-[14px]"
            disabled={!habilitarContinuar}
            onClick={() => continuar()}
          >
            Continuar
          </button>
        </div>
      </div>
    </section>
  );
};

export default PedidoEsim;
