import React from "react";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import "./style.css";
import PrincipalBanner from "../../components/PrincipalBanner";
import Acordeon from "../../components/Acordeon";
import DetalhesPedido from "../../components/DetalhesPedido";
import C6Cartoes from "../../components/C6Bonus";
import TimControle from "../../components/TimControle";
import RestanteTimControle from "../../components/RestanteTimControle";
import BotaoWhatsappFlutuante from "../../components/BotaoWhatsappFlutuante";

const PrincipaisDuvidas = [
  {
    title: "O que é o TIM Empresas?",
    text: `O TIM Empresas é um plano mensal que entrega benefícios equivalentes ao valor pago pelo cliente, sendo eles: Muitos minutos para usar com qualquer operadora, Internet ilimitada para determinados Apps, quantidade de GB para utilizar com o que quiser e franquia de SMS. Estes benefícios podem variar de acordo com o plano escolhido pelo cliente.`,
  },
  {
    title: "Como eu posso contratar um TIM Empresas?",
    text: `Você pode contratar um plano TIM Empresas aqui em nossa página. O TIM Empresas também pode ser contratado por telefone, ligando para (21) 3037-9941 ou indo até uma loja TIM.`,
  },
  {
    title: "Quais os benefícios de um plano TIM Empresas?",
    text: `O TIM Empresas conta com os seguintes benefícios: Muitos minutos para usar com qualquer operadora, franquia de SMS, pacote de internet para usar como quiser e Apps ilimitados. Estes benefícios podem mudar de acordo com o plano que você escolheu. Caso você ainda não tenha um número TIM e queira saber como trazer seu número, acesse a pagina de portabilidade`,
  },
  {
    title: "O meu TIM Empresas dá desconto em aparelhos?",
    text: `Sim, o TIM Empresas dá descontos em muitos aparelhos! Você pode verificar todos os smartphones com desconto em nossa Loja Online`,
  },
  {
    title: "Qual a diferença entre um plano controle e um pós-pago?",
    text: `A mecânica é bem parecida. O grande diferencial é que, caso a franquia do plano controle termine antes da renovação e você queira retomá-la, será preciso fazer uma recarga assim como ocorre no plano pré-pago.`,
  },
  {
    title: "Como eu posso pagar o meu TIM Empresas?",
    text: `Temos a opção de pagamento via fatura, cartão de crédito e débito automático.`,
  },
  {
    title: "Como a conta do TIM Empresas chega na minha casa?",
    text: `Ela pode chegar de duas maneiras: como fatura impressa ou como Conta Digital. Na segunda opção, ela vai chegar pelo seu e-mail sem você pagar nada a mais por isso.`,
  },
  {
    title: "Como vejo meu consumo de internet?",
    text: `Para consultar seu consumo de internet, você pode: Acessar o Meu TIM e enviar um SMS com a palavra WEB para 4141 ou ligar *144# e escolher a opção de Consulta de Internet.`,
  },
  {
    title: "Caso eu utilize todo meu pacote de internet das ofertas, o que acontece?",
    text: `Após o término da franquia mensal, você terá sua navegação interrompida. Mas não se preocupe! Seus Apps incluídos no plano são ilimitados! Então para os planos que contam com: WhatsApp, Facebook Messenger, Instagram, Facebook e Twitter continuam funcionando e, caso você queira usar mais internet para outras finalidades, basta contratar pacotes adicionais de internet para continuar navegando.`,
  }
];

const Principal = () => {
  return (
    <div className="flex flex-col bg-[#FAFAFA]">
      <Menu cor1="#072198" cor2="#080FBE" />
      <PrincipalBanner />
      <TimControle />
      <RestanteTimControle />
      <section className="bg-[#072198] w-full justify-center flex py-8">
        <div className="md:max-w-[990px] md:px-0 px-4">
          <Acordeon typeColor="azul" type="default" headerText={"Principais Dúvidas"} elements={PrincipaisDuvidas} />
        </div>
      </section>
      <Footer />
      <BotaoWhatsappFlutuante/>
    </div>
  );
};

export default Principal;
