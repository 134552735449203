import React from "react";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import EsimBanner from "../../components/EsimBanner";
import PedidoEsim from "../../components/PedidoEsim";
import FuncionalidadesEsim from "../../components/FuncionalidadesEsim";
import Acordeon from "../../components/Acordeon";

const PerguntasFrequentes = [
  {
    title: "Como funciona o uso de Dual SIM com E-SIM:",
    text: `O celular consegue operar com duas linhas e é possível cadastrar mais de uma operadora. Nas viagens, o eSIM facilita a contratação de planos e serviços online, via QR Code ou Apps das operadoras locais. Ele também é mais seguro pois não pode ser removido. Em casos de perda ou roubo, o aparelho permanece conectado à internet, podendo ser localizado.`,
  },
  {
    title: "Posso usar os números instalados no eSIM simultaneamente?",
    text: `Não, você poderá usar apenas um número ativo. O que pode ser usado simultaneamente é um perfil do eSIM e o número do sim card físico.`,
  },
  {
    title: "Posso ter mais de um número no eSIM?",
    text: `É possível armazenar até 8 perfis no eSim. Contudo, somente um perfil pode ser usado de cada vez.`,
  },
  {
    title: "Quantas linhas ativas posso ter no smartphone?",
    text: `Uma linha pelo chip físico e uma linha ativa no eSIM.`,
  },
  {
    title: "Quais dispositivos suportam dual SIM com E-SIM",
    text: `<b>Apple: </b><br>
    iPhone SE 2ª e 3° Geração, iPhone XR, iPhone XS, XS Max, iPhone 11, 11 Pro, 11 Pro Max, iPhone 12,12 Mini, 12 Pro, 12 Pro Max, iPhone 13, 13 Mini, 13 Pro, 13 Pro Max, iPhone 14, 14 plus, 14 Pro e 14 Pro Max.
    <br>
    <br>
    <b>Samsung: </b><br>
    Galaxy S20, Galaxy S20+, Galaxy S20 Ultra, Galaxy Note 20, Galaxy Note 20 Ultra, Galaxy S21, Galaxy S21+, Galaxy S21 Ultra, Galaxy Z Flip, Galaxy Z Fold 2, Samsung Galaxy S22, Samsung Galaxy S22+, Samsung Galaxy S22 Ultra, Samsung Galaxy Fold 4 e Samsung Galaxy Flip 4.
    <br>
    <br>
    <b>Motorola:</b><br>
    Motorola Razer.`,
  },
];

const Esim = () => {
  return (
    <div className="flex flex-col">
      <Menu cor1="#072198" cor2="#072198" />
      <EsimBanner />
      <PedidoEsim />
      <FuncionalidadesEsim />
      <section className="bg-white w-full justify-center flex py-5">
        <div className="md:max-w-[990px] md:px-0 px-4">
          <div className="flex flex-col items-center gap-2">
            <span>Saiba como ativar o seu eSIM.</span>
            <button
              className="text-white font-bold bg-[#002198] px-4 py-1 rounded-[10px] text-[14px]"
              onClick={() => {
                window.location.href = "/esim/como-ativar-seu-esim";
              }}
            >
              Clique aqui
            </button>
          </div>
        </div>
      </section>

      <div className="w-full flex justify-center mt-10">
        <b>Perguntas frequentes</b>
      </div>
      <section className="bg-[#FFFFFF] w-full justify-center flex py-8">
        <div className="md:max-w-[990px] md:px-0 px-4">
          <Acordeon typeColor="white" noShow type="c6" elements={PerguntasFrequentes} />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Esim;
