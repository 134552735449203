import React from "react";
import "./style.css";
import LogoNovaSVG from "../../assets/Global/LogoTimParceiro.svg";

import awsLogo from "../../assets/Principal/powered-by-aws.png";
import googleSiteSeguro from "../../assets/Principal/google-site-seguro.png";
import revendaAutorizada from "../../assets/Global/revendedor-autorizado-tim.png";
import reclameAqui from "../../assets/Global/ra.jpeg";

const Footer = ({ minWidth = "md:min-w-[990px]", bgColor = "bg-[#002198]" }) => {
  return (
    <section className={`flex w-full justify-center text-white pt-[65px] ${bgColor}`}>
      <div className={`${minWidth} px-10 md:px-0 flex flex-col`}>
        <div className="flex flex-col gap-4 md:flex-row justify-between">
          {/* START: COLUNA 1 */}
          <div className="flex flex-col items-start">
            <span>Fale conosco:</span>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents/politica-de-privacidade-e-cookies/Política de Privacidade TIM 1.3.pdf"
              className="text-white block underline"
            >
              Política de privacidade
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents%2Fdocumentos-e-termos-de-politica-2023%2FPol%C3%ADtica%20de%20Cookies%20TIM%20.pdf?alt=media&amp;token=4d1c913f-6a86-4a10-9968-b9226e217f9c"
              className="text-white block underline"
            >
              Política de cookies
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents/FAQ LGPD TIM 1.3.pdf"
              className="text-white block underline"
            >
              FAQ LGPD
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/meuplanotim-com-br.appspot.com/o/documents%2Fdocumentos-e-termos-de-politica-2023%2FTermos%20de%20Uso%20do%20Site%20TIM.pdf?alt=media&amp;token=c159501e-160c-4b85-bb2e-64f360b7d2c7"
              className="text-white block underline"
            >
              Termos de uso
            </a>
          </div>
          {/* END: COLUNA 1 */}
          {/* START: COLUNA 2 */}
          <div>
            {/* <div>
              <small className="font-light text-[15px]">Televendas:</small>
              <a href="tel:81996716091" rel="noopener" className="pl-1 text-white no-underline font-bold text-[14]">
                (81) 9.9671-6091
              </a>
            </div> */}
            <div className="pt-2">
              <div className="font-light text-[14px]">Fale com nosso SAC:</div>
              <small className="font-light text-[12px]">(Clientes TIM): </small>
              <a href="tel:144" rel="noopener" className="pl-1 text-white no-underline font-bold text-[14px]">
                *144
              </a>
            </div>
            <div className="pb-2">
              <small className="font-light text-[12]">(Clientes de outras operadoras): </small>
              <a href="tel:1056" rel="noopener" className="pl-1 text-white no-underline font-bold text-[14px]">
                1056
              </a>
            </div>
            <div>
              Horário de atendimento:
              <br /> 2ª a 6ª feira: <strong>9:00 às 20:00</strong>
              <br />
              Sábado: <strong>10:00 às 16:00</strong>
            </div>
          </div>
          {/* END: COLUNA 2 */}
          {/* START: COLUNA 3 */}
          <div></div>
          {/* END: COLUNA 3 */}
          {/* START: COLUNA 4 */}
          <div className="flex">
            <div className="flex flex-row gap-5 md:gap-0">
              <div>
                <a
                  href="https://aws.amazon.com/pt/what-is-cloud-computing"
                  style={{ borderRadius: "4px" }}
                  className="float-right text-white h-[48px] w-[88px] text-center bg-white"
                >
                  <img style={{ margin: "auto" }} src={awsLogo}></img>
                </a>
              </div>
              <div className="flex md:flex-col  flex-row md:gap-2 gap-5">
                <div>
                  <a
                    href="https://transparencyreport.google.com/safe-browsing/search?url=planostim.online"
                    className="float-right text-white"
                  >
                    <img className="bg-center bg-cover bg-no-repeat h-[48px] w-[88px]" src={googleSiteSeguro}></img>
                  </a>
                </div>
                <div>
                  <a
                    href="https://transparencyreport.google.com/safe-browsing/search?url=planostim.online"
                    className="float-right text-white"
                  >
                    <img className="bg-center bg-cover bg-no-repeat h-[48px] w-[88px]" src={LogoNovaSVG}></img>
                  </a>
                </div>
                <div className="w-auto ">
                  <a
                    href="https://www.reclameaqui.com.br/empresa/vertex/?utm_source=referral&utm_medium=embbed&utm_campaign=reputacao&utm_term=vertical"
                    className="float-right text-white"
                  >
                    <img className="bg-center bg-cover bg-no-repeat h-[96px] w-[96px]" src={reclameAqui}></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* END: COLUNA 4 */}
        </div>
        {/* copyright */}
        <div className="py-1">
          <hr role="separator" aria-orientation="horizontal" className="bg-white my-4" />
        </div>
        <div className="py-6 text-center">
          <span>© 2024 CMD Telcom Comércio e Serviços Ltda. CNPJ: 46.939.610/0001-56. Todos os direitos reservados.</span>
        </div>
      </div>
    </section>
  );
};

export default Footer;
