import { useEffect, useState } from "react";
import "./style.css";
import Like from "../../assets/TimBlack/like-icon.png";
import Banca from "../../assets/TimBlack/banca-jornais-icon.png";
import Audio from "../../assets/TimBlack/audiobooks-icon.png";
import Shield from "../../assets/TimBlack/shield-icon.png";
import Reforca from "../../assets/TimBlack/reforca-icon.png";

const OutrosServicosTimBlack = () => {
  return (
    <section className="bg-[#002CCC] w-full justify-center flex py-8 text-white">
      <div className="md:max-w-[990px] md:px-0 px-4 md:px-0">
        <div className="w-full gap-8 flex md:flex-row md:justify-between flex-col bg-[#002CCC]">
          <div className="md:w-[30%] flex flex-col gap-4">
            <span className="text-center md:text-start">Mais benefícios</span>
            <span className="text-[26px] md:text-[32px] md:leading-[44px] leading-[30px] text-center md:text-start">
              Confira outros serviços inclusos no plano
            </span>
          </div>
          <div className="md:w-[70%] md:flex-row flex-col flex gap-5">
            <div className="flex md:flex-col flex-row gap-5">
              <div className="card-outros-servicos">
                <img src={Like} className="w-[40px]" />
                <span className="font-medium text-[15px]">Redes sociais inclusas</span>
                <span className="text-[13px]">WhatsApp, Instagram, Facebook e Twitter não descontam da internet.</span>
              </div>
              <div className="card-outros-servicos">
                <img src={Shield} className="w-[40px]" />
                <span className="font-medium text-[15px]">TIM Segurança Digital Premium</span>
                <span className="text-[13px]">Antivírus, backup e assistência remota para seu dispositivo.</span>
              </div>
            </div>

            <div className="flex md:flex-col flex-row gap-5">
              <div className="card-outros-servicos">
                <img src={Banca} className="w-[40px]" />
                <span className="font-medium text-[15px]">Tim Banca Jornais</span>
                <span className="text-[13px]">+ de 150 revistas + 9 jornais sem descontar da internet.</span>
              </div>
              <div className="card-outros-servicos">
                <img src={Reforca} className="w-[40px]" />
                <span className="font-medium text-[15px]">Reforça</span>
                <span className="text-[13px]">Reforço educacional diretamente no seu celular</span>
              </div>
            </div>

            <div className="flex md:flex-col flex-row gap-5">
              <div className="card-outros-servicos">
                <img src={Audio} className="w-[40px]" />
                <span className="font-medium text-[15px]">Audiobooks by Ubook</span>
                <span className="text-[13px]">Os best-sellers mais vendidos para você ouvir quando e onde quiser.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OutrosServicosTimBlack;
