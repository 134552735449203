import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AiOutlineCheck } from "react-icons/ai";
import Modal from "../../components/Modal";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { AiOutlineDown } from "react-icons/ai";
import { estados, vencimentos, tiposConta, bancos, operadoras } from "../../utils/dados";
import "./style.css";
import CadastroPlanoInfo from "../../components/CadastroPlanoInfo";
import { AdicionaLinha } from "../../utils/google-sheet";
import { sendEmail } from "../../utils/mail";
import CircularProgress from "@mui/material/CircularProgress";

const Cadastro = ({ planoInfo, tipoPlano }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [stepsFinished, setStepsFinished] = useState([]);
  const [operadoraMigracao, setOperadoraMigracao] = useState("");
  const [modalOpened, setModalOpened] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [banco, setBanco] = useState("");
  const [estado, setEstado] = useState("");
  const [vencimento, setVencimento] = useState("");
  const [tipoDeConta, setTipoDeConta] = useState("");
  const [pagamentoDebitoAutomatico, setPagamentoDebitoAutomatico] = useState(false);
  const [ddd, setDDD] = useState("");
  const [showDDD, setShowDDD] = useState(false);
  const [planoEsim, setPlanoEsim] = useState(false);
  const [hidePlanoInfo, setHidePlanoInfo] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    cnpj: "",
    telefone: "",
    razaoSocial: "",
    telefoneMigracao: "",
    telefonePortabilidade: "",
    nomeTitular: "",
    cpfTitular: "",
    cep: "",
    cidade: "",
    estado: "",
    rua: "",
    numero: "",
    complemento: "",
    bairro: "",
    concordo: false,
    agencia: "",
    conta: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: false,
    cnpj: false,
    telefone: false,
    razaoSocial: false,
    telefoneMigracao: false,
    telefonePortabilidade: false,
    nomeTitular: false,
    cpfTitular: false,
    cep: false,
    cidade: false,
    estado: false,
    rua: false,
    numero: false,
    complemento: false,
    bairro: false,
    concordo: false,
    agencia: false,
    conta: false,
  });
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    cnpj: false,
    telefone: false,
    razaoSocial: false,
    telefoneMigracao: false,
    telefonePortabilidade: false,
    nomeTitular: false,
    cpfTitular: false,
    cep: false,
    cidade: false,
    estado: false,
    rua: false,
    numero: false,
    complemento: false,
    bairro: false,
    concordo: false,
    agencia: false,
    conta: false,
  });
  const [selectedService, setSelectedService] = useState("");

  useEffect(() => {
    validateStep();
  }, [formValues, selectedService]);

  const validateStep = () => {
    let stepValid = false;

    if (activeStep === 1) {
      const requiredFieldsStep1 = ["email", "cnpj", "telefone", "razaoSocial"];
      stepValid = requiredFieldsStep1.every(
        (field) =>
          formValues[field].trim() !== "" &&
          !formErrors[field] &&
          (field === "cnpj" ? formValues[field].replace(/\D/g, "").length === 14 : true) &&
          (field === "telefone" ? formValues[field].replace(/\D/g, "").length === 11 : true)
      );

      if (selectedService === "service1") {
        stepValid = stepValid && formValues.telefoneMigracao.replace(/\D/g, "").length === 11;
      } else if (selectedService === "service2") {
        stepValid = stepValid && formValues.telefonePortabilidade.replace(/\D/g, "").length === 11;
      } else if (selectedService === "service3") {
        stepValid = stepValid && ddd.length >= 1;
      } else {
        stepValid = false; // No service selected
      }
    } else if (activeStep === 2) {
      const requiredFieldsStep2 = ["nomeTitular", "cpfTitular", "cep", "cidade", "estado", "rua", "numero", "bairro"];
      stepValid = requiredFieldsStep2.every((field) => formValues[field].trim() !== "" && !formErrors[field]);
    } else if (activeStep === 3) {
      if (pagamentoDebitoAutomatico) {
        const requiredFieldsStep3 = ["banco", "agencia", "conta"];
        stepValid = requiredFieldsStep3.every((field) => formValues[field]?.trim() !== "" && !formErrors[field]);
      } else {
        stepValid = true;
      }
      stepValid = stepValid && formValues.concordo;
    }

    if (stepValid) {
      setStepsFinished((prev) => [...new Set([...prev, activeStep])]);
    } else {
      setStepsFinished((prev) => prev.filter((step) => step !== activeStep));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormValues({
      ...formValues,
      [name]: fieldValue,
    });

    if (fieldValue === "" || (type === "checkbox" && !checked)) {
      setFormErrors({
        ...formErrors,
        [name]: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouchedFields({
      ...touchedFields,
      [name]: true,
    });
    if (value.trim() === "") {
      setFormErrors({
        ...formErrors,
        [name]: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    }
    validateStep();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateStep();

    if (!Object.values(formErrors).some((error) => error)) {
      nextStep();
    }
  };

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const nextStep = async () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 3) {
      setLoading(true);
      var dadosPlanilha = [
        planoInfo.nome,
        formValues.email,
        formValues.cnpj,
        formValues.telefone,
        formValues.razaoSocial,
        selectedService,
      ];
      if (selectedService === "service1") {
        dadosPlanilha.push(formValues.telefoneMigracao);
      } else if (selectedService === "service2") {
        dadosPlanilha.push(
          operadoras.find((e) => e.index === operadoraMigracao).nome + "#" + formValues.telefonePortabilidade
        );
      } else {
        dadosPlanilha.push(ddd);
      }
      dadosPlanilha.push(planoEsim);

      // Nome Nome mae CEP	Cidade	Estado	Rua	Numero	Complemento	Bairro
      dadosPlanilha.push(
        formValues.nomeTitular,
        formValues.cpfTitular,
        formValues.cep,
        formValues.cidade,
        formValues.estado,
        formValues.rua,
        formValues.numero,
        formValues.complemento,
        formValues.bairro
      );
      // Dados pagamento
      dadosPlanilha.push(vencimento, tipoDeConta, pagamentoDebitoAutomatico);
      if (pagamentoDebitoAutomatico) {
        dadosPlanilha.push(`Banco: ${banco}, Agencia: ${formValues.agencia} , Conta: ${formValues.conta}`);
      }
      const key =
        "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDFXrQw5zHxL/7H\nQuAnxSabJk8XwN4ieQLpKsJ70QtD6au9mEk9+3JoUJkWbMnB76a43jpAnu4hQ4XS\nEx3wn2sHN9XHF4fB01NI+v1VHyDSeA4BsI+vBxvFK2fViiCGxvKG89MDW83az4qb\nsLDw9NRUtMgWnA1LYR7v6TPycPttZUy6XMoxm7W4xDQNdXMFWiWWHE0MDJf50Or7\n5w30vO0GXURcoubH1xOz5+wxicFLqCmSV44kVizDYEeNwElIjfSxrFQ4XyUTd9UU\nUIk6SndvEsEadoLulJqZ4k523g+YPYyeesxjLnovHmM/rtYALqtrHcQRCy41541/\n3RT6zqmRAgMBAAECggEAATz+s2nX6d6hDL381bMKcsxoOvVrpLAOq8jSud/OCsne\nA81UnvjDYdJ+S2I5An2kQE0An47VCUy4+7WWqVT5YRe2wHjY0gdR4pzUgbgKxtdC\n+zwQ2ApwNan34k1AtqfBNhs0gTUOaGeHQ+0gBxBPfrfi+NACKK1oz3IumxWeC4nQ\nK5nSiLEAAdELqxUSz5HSB9kN5caJ5agB00EzOc7hEEQnoMM8FGN/7lhlODFWw62o\njQpsj3JuxiVnb9PjJEddPuMwLxSVOoShu7WAs9NOLKuD2M16PTr1Z1AEzDUQE91V\n/mXo8+wIWudSlJHOGeLs8VL50hVnS9ZsyUbZ4KpKMwKBgQDshA9hGJNEw5bYvDB2\nVN1WbTiu52iN8Th6u+wYNGzRVavNNr7SMrwDs08jBN0cvwNqmeddZ1OMF07nHthA\nqP2LIymqvdSxLzDWzVPeYotkqdqQp/dDiZ6MCQYpo+DpoOaqmSzYX0jGIMKQuGPQ\nz8SgMhurRsYcHihYF3vaco5odwKBgQDVoRYEbrnDkaERxNJ95b5US5lfkngJf0OQ\n+19r1goKKpcsfDWIuk9EZhaDsprSMoQE/q4T+HmDkUnGmx44digBNxLbiTa5xQaa\ncjc/pt2vemMjsX2qQGoyGAQ77Yl5pllbmNI/qgquLlAbPAcMcAIsRge2y2AgKLsw\nMoqj88GINwKBgDYrYOJaIJClQ01MfMT9VtxOk6brdTTdRJRHqiE5EJs5RenwWfAG\nkq0+Sie5G3NUBFJMAYORAL+bLwUcOWdFzRwq54YliCs0pbjsugK7CzX4+QgckDM1\nt8xW3MR4tjCMG4iDIJRF7oMCSc2fjazriRsvnsqxoPobph3W8xTrG9atAoGAFKm/\nGnCnUS5N2Q0jmv/U2Axz6lWdfLWtkGFwClsqn28dLjVZbD8xGZmSRezJotk2AdGs\nfswC3OH5s9cfJjDvj6aHEWsFOxS+ncO8alC2kpmQ60vorsYKP4wpJK7GJFkdvYlb\nlMLaw5xBLVEyEVboLuYbKugjBRlLec75sAAM0SMCgYASac+R/muCJNkG1m/cTryK\nafpym9+10xkI6reTjagHi3TjVxedMVeLf24RTtnMKihO3dX4of3owBLM5W1pstqg\ncwSMaCYKbvpv8YsZYlxdlECRAuXkJBhhqGdtooWSIzY8F7ljb/mYCkCUSkupI4nA\nUst7c1y2a+e2LrDxjXE+SQ==\n-----END PRIVATE KEY-----\n";
      const mail = "googlesheeteditor@alert-tine-429313-n2.iam.gserviceaccount.com";
      const planilhaId = "1xCw25wgJZL2CvpUTEKflBE8r9d5NT4Ja4z5ls7HQATw";
      AdicionaLinha(planilhaId, { mail: mail, key: key }, dadosPlanilha);
      if (window.location.hostname != "localhost") {
        sendEmail(formValues.email);
      }
      console.log(isLoading);
      await sleep(4000);
      setLoading(false);
      setHidePlanoInfo(true);
      setActiveStep(100);
    }
  };

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  const handleChangeEstado = (event) => {
    setShowDDD(false);
    setEstado(event.target.value);
    const selectedEstado = estados.find((e) => e.index === event.target.value);

    if (Array.isArray(selectedEstado.ddd)) {
      setDDD("");
    } else {
      const dddString = String(selectedEstado.ddd);
      setDDD(dddString);
      setShowDDD(true);
    }
  };

  useEffect(() => {
    validateStep();
  }, [ddd]);

  const handleChangeDDD = (event) => {
    console.log(event.target.value);
    const dddString = event.target.value + "";
    setDDD(dddString);
    validateStep();
  };

  const chooseDDD = () => {
    setModalOpened(false);
  };

  const back = () => {
    if (activeStep === 1) {
      window.location.href = "/";
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const nextStepAvailable = () => {
    return stepsFinished.includes(activeStep);
  };

  const getInfo = (info) => {
    console.log(info);
  };

  const selectedEstado = estados.find((e) => e.index === estado);
  const ddds = selectedEstado && Array.isArray(selectedEstado.ddd) ? selectedEstado.ddd : [selectedEstado?.ddd];

  return (
    <div className="flex flex-col bg-[#FAFAFA]">
      <Modal header="Escolha sua região" open={modalOpened} handleClose={() => setModalOpened(false)}>
        <div className="flex flex-col gap-5">
          <span className="text-[15px]">Quero uma nova linha com um número TIM para o DDD</span>
          <FormControl variant="standard" className="w-full mb-4">
            <Select
              className="mb-4"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={estado}
              onChange={handleChangeEstado}
            >
              {estados.map((estado) => (
                <MenuItem key={estado.index} value={estado.index}>
                  {estado.nome}
                </MenuItem>
              ))}
            </Select>
            {ddds.length > 1 && (
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={ddd}
                onChange={handleChangeDDD}
              >
                {ddds.map((ddd) => (
                  <MenuItem key={ddd} value={ddd}>
                    {ddd}
                  </MenuItem>
                ))}
              </Select>
            )}
            {ddd.length >= 1 && showDDD && (
              <div className="text-[14px] flex w-full justify-center p-1 border-2 border-[#1532A1] mt-5 text-[#1532A1] rounded-full">
                <span>DDD {ddd}</span>
              </div>
            )}
          </FormControl>
          <button
            style={{ backgroundColor: `${ddd ? "#002198" : "#e0e0e0"}`, color: `${ddd ? "white" : "#A6A6A6"}` }}
            className={`w-full rounded-[8px] py-2 text-[14px] font-medium`}
            disabled={!ddd}
            onClick={() => chooseDDD()}
          >
            Escolher este DDD
          </button>
        </div>
      </Modal>
      <Menu cor1="#002198" cor2="#002198" />
      <section className="flex md:flex-row flex-col-reverse bg-white w-full min-h-[70vh]">
        {isLoading ? (
          <Box className="flex-grow w-full justify-center items-center" sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="flex flex-grow flex-col gap-6 md:px-14 px-8 py-5">
            {activeStep !== 100 && (
              <div className="flex items-start w-full">
                <div
                  className="flex gap-1 flex-col justify-center items-center w-[20%]"
                  style={{ color: `${activeStep === 1 || stepsFinished.includes(1) ? "#002198" : "#9E9E9E"}` }}
                >
                  <div
                    className="flex justify-center items-center rounded-full h-[25px] w-[25px]"
                    style={{
                      backgroundColor: `${activeStep === 1 || stepsFinished.includes(1) ? "#002198" : "#9E9E9E"}`,
                    }}
                  >
                    {stepsFinished.includes(1) ? (
                      <AiOutlineCheck style={{ color: "white", width: "14px" }} />
                    ) : (
                      <span className="text-white text-[13px]">1</span>
                    )}
                  </div>
                  <span className="text-[13px]">Cadastro</span>
                </div>
                <hr role="separator" aria-orientation="horizontal" className="custom-hr" />
                <div
                  className="flex gap-1 flex-col justify-center items-center  w-[40%]"
                  style={{ color: `${activeStep === 2 || stepsFinished.includes(2) ? "#002198" : "#9E9E9E"}` }}
                >
                  <div
                    className="flex justify-center items-center rounded-full h-[25px] w-[25px]"
                    style={{
                      backgroundColor: `${activeStep === 2 || stepsFinished.includes(2) ? "#002198" : "#9E9E9E"}`,
                    }}
                  >
                    {stepsFinished.includes(2) ? (
                      <AiOutlineCheck style={{ color: "white", width: "14px" }} />
                    ) : (
                      <span className="text-white text-[13px]">2</span>
                    )}
                  </div>
                  <span className="text-[13px] text-center">Dados da empresa</span>
                </div>
                <hr role="separator" aria-orientation="horizontal" className="custom-hr" />
                <div
                  className="flex gap-1 flex-col justify-center items-center  w-[70%]"
                  style={{ color: `${activeStep === 3 || stepsFinished.includes(3) ? "#002198" : "#9E9E9E"}` }}
                >
                  <div
                    className="flex justify-center items-center rounded-full h-[25px] w-[25px]"
                    style={{
                      backgroundColor: `${activeStep === 3 || stepsFinished.includes(3) ? "#002198" : "#9E9E9E"}`,
                    }}
                  >
                    {stepsFinished.includes(3) ? (
                      <AiOutlineCheck style={{ color: "white", width: "14px" }} />
                    ) : (
                      <span className="text-white text-[13px]">3</span>
                    )}
                  </div>
                  <span className="text-[13px] text-center">Dados de pagamento</span>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <>
                <div className="flex flex-col">
                  <b className="text-[20px]">VAMOS COMEÇAR</b>
                  <span className="font-normal">Para completar o seu pedido, preencha abaixo com os seus dados:</span>
                </div>
                <div className="flex flex-col w-full">
                  <Box
                    component="form"
                    sx={{
                      width: "100%",
                      "& > :not(style)": { m: 0, width: "70%" },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex gap-4 flex-col md:flex-row w-full">
                      <TextField
                        error={touchedFields.email && formErrors.email}
                        helperText={touchedFields.email && formErrors.email ? "Obrigatório" : ""}
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                      />

                      <InputMask
                        mask="99.999.999/9999-99"
                        value={formValues.cnpj}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {() => (
                          <TextField
                            error={
                              touchedFields.cnpj &&
                              (formErrors.cnpj || formValues.cnpj.replace(/\D/g, "").length !== 14)
                            }
                            helperText={
                              touchedFields.cnpj &&
                              (formErrors.cnpj || formValues.cnpj.replace(/\D/g, "").length !== 14)
                                ? "CNPJ inválido"
                                : ""
                            }
                            id="standard-basic"
                            label="CNPJ"
                            variant="standard"
                            name="cnpj"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </div>
                    <div className="flex gap-4 flex-col md:flex-row ">
                      <InputMask
                        mask="(99) 99999-9999"
                        value={formValues.telefone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {() => (
                          <TextField
                            error={
                              touchedFields.telefone &&
                              (formErrors.telefone || formValues.telefone.replace(/\D/g, "").length !== 11)
                            }
                            helperText={
                              touchedFields.telefone &&
                              (formErrors.telefone || formValues.telefone.replace(/\D/g, "").length !== 11)
                                ? "Telefone inválido"
                                : ""
                            }
                            id="standard-basic"
                            label="Telefone de contato"
                            variant="standard"
                            name="telefone"
                            fullWidth
                          />
                        )}
                      </InputMask>

                      <TextField
                        error={touchedFields.razaoSocial && formErrors.razaoSocial}
                        helperText={touchedFields.razaoSocial && formErrors.razaoSocial ? "Obrigatório" : ""}
                        id="standard-basic"
                        label="Razão Social"
                        variant="standard"
                        name="razaoSocial"
                        value={formValues.razaoSocial}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </div>
                  </Box>
                </div>
                <div className="flex flex-col w-full mt-4">
                  <span>Escolha o serviço que deseja utilizar:</span>
                  <RadioGroup name="services" value={selectedService} onChange={handleServiceChange} row>
                    <div className="flex flex-col">
                      <FormControlLabel
                        value="service1"
                        control={<Radio />}
                        label="Sou cliente pré da TIM e quero um plano pré Pessoa Jurídica (Migração)"
                      />
                      {selectedService === "service1" && (
                        <div className="py-4">
                          <div>
                            <InputMask
                              mask="(99) 99999-9999"
                              value={formValues.telefoneMigracao}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {() => (
                                <TextField
                                  error={
                                    touchedFields.telefoneMigracao &&
                                    (formErrors.telefoneMigracao ||
                                      formValues.telefoneMigracao.replace(/\D/g, "").length !== 11)
                                  }
                                  helperText={
                                    touchedFields.telefoneMigracao &&
                                    (formErrors.telefoneMigracao ||
                                      formValues.telefoneMigracao.replace(/\D/g, "").length !== 11)
                                      ? "Telefone para Migração inválido"
                                      : ""
                                  }
                                  id="standard-basic"
                                  label="Telefone para Migração"
                                  variant="standard"
                                  name="telefoneMigracao"
                                  fullWidth
                                />
                              )}
                            </InputMask>
                          </div>
                        </div>
                      )}

                      <FormControlLabel
                        value="service2"
                        control={<Radio />}
                        label="Sou de outra operadora e quero mudar para a TIM (Portabilidade)"
                      />
                      {selectedService === "service2" && (
                        <div className="py-4">
                          <FormControl variant="standard" className="w-full mb-4">
                            <InputLabel id="demo-simple-select-standard-label">Operadora atual</InputLabel>
                            <Select
                              className="mb-4"
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={operadoraMigracao}
                              onChange={(e) => {
                                setOperadoraMigracao(e.target.value);
                              }}
                              label="Operadora atual"
                            >
                              {operadoras.map((estado) => (
                                <MenuItem key={estado.index} value={estado.index}>
                                  {estado.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div>
                            <InputMask
                              mask="(99) 99999-9999"
                              value={formValues.telefonePortabilidade}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {() => (
                                <TextField
                                  error={
                                    touchedFields.telefonePortabilidade &&
                                    (formErrors.telefonePortabilidade ||
                                      formValues.telefonePortabilidade.replace(/\D/g, "").length !== 11)
                                  }
                                  helperText={
                                    touchedFields.telefonePortabilidade &&
                                    (formErrors.telefonePortabilidade ||
                                      formValues.telefonePortabilidade.replace(/\D/g, "").length !== 11)
                                      ? "Telefone para Portabilidade inválido"
                                      : ""
                                  }
                                  id="standard-basic"
                                  label="Telefone para Portabilidade"
                                  variant="standard"
                                  name="telefonePortabilidade"
                                  fullWidth
                                />
                              )}
                            </InputMask>
                          </div>
                        </div>
                      )}

                      <FormControlLabel
                        value="service3"
                        control={<Radio />}
                        label="Quero uma nova linha com um número TIM (Ativação)"
                      />
                      {selectedService === "service3" && (
                        <div className="py-4">
                          <button
                            className="hover:bg-[#EBEEF8] py-1 text-[13px] justify-between px-4 flex min-w-[250px] items-center text-[#002198] border-[#002198] border-[1px] rounded-[9px]"
                            onClick={() => setModalOpened(true)}
                          >
                            <div></div>
                            <span className="font-medium">
                              {ddd ? `${selectedEstado.nome} | ${ddd}` : "Escolha sua região"}
                            </span>
                            <AiOutlineDown className="w-3" />
                          </button>
                        </div>
                      )}
                    </div>
                  </RadioGroup>
                </div>
                {/* <div className="flex items-center">
                <FormGroup className="mr-[-20px]">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={planoEsim}
                        onChange={() => {
                          setPlanoEsim(!planoEsim);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </FormGroup>
                <span>Quero meu plano com eSIM.</span>
              </div> */}
              </>
            )}

            {/* START: PASSO 2 */}
            {activeStep === 2 && (
              <div className="flex flex-grow flex-col gap-6">
                <div className="flex flex-col">
                  <b className="text-[20px]">DADOS DA EMPRESA</b>
                  <span className="font-normal">Para completar o seu pedido, preencha abaixo com os seus dados:</span>
                </div>
                <div className="flex flex-col gap-5">
                  <Box
                    component="form"
                    sx={{
                      width: "100%",
                      "& > :not(style)": { m: 0, width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col gap-3">
                      <div className="flex flex-col md:flex-row md:gap-4">
                        <TextField
                          error={touchedFields.nomeTitular && formErrors.nomeTitular}
                          helperText={touchedFields.nomeTitular && formErrors.nomeTitular ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Nome do titular"
                          variant="standard"
                          name="nomeTitular"
                          value={formValues.nomeTitular}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />

                        <InputMask
                          mask="999.999.999-99"
                          value={formValues.cpfTitular}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {() => (
                            <TextField
                              error={
                                touchedFields.cpfTitular &&
                                (formErrors.cpfTitular || formValues.cpfTitular.replace(/\D/g, "").length !== 11)
                              }
                              helperText={
                                touchedFields.cpfTitular &&
                                (formErrors.cpfTitular || formValues.cpfTitular.replace(/\D/g, "").length !== 11)
                                  ? "CPF do titular inválido"
                                  : ""
                              }
                              id="standard-basic"
                              label="CPF do titular"
                              variant="standard"
                              name="cpfTitular"
                              fullWidth
                            />
                          )}
                        </InputMask>
                      </div>

                      <div className="flex flex-col md:flex-row md:gap-4">
                        <TextField
                          error={touchedFields.cep && formErrors.cep}
                          helperText={touchedFields.cep && formErrors.cep ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="CEP"
                          variant="standard"
                          name="cep"
                          value={formValues.cep}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            const validValue = value.replace(/[^0-9.-]/g, ""); // Permite apenas números, ponto e hífen
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              [name]: validValue,
                            }));
                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              [name]: validValue.trim() === "",
                            }));
                          }}
                          onBlur={handleBlur}
                          fullWidth
                        />

                        <TextField
                          error={touchedFields.cidade && formErrors.cidade}
                          helperText={touchedFields.cidade && formErrors.cidade ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Cidade"
                          variant="standard"
                          name="cidade"
                          value={formValues.cidade}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                        <TextField
                          error={touchedFields.estado && formErrors.estado}
                          helperText={touchedFields.estado && formErrors.estado ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Estado"
                          variant="standard"
                          name="estado"
                          value={formValues.estado}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </div>

                      <div className="flex flex-col md:flex-row md:gap-4">
                        <TextField
                          error={touchedFields.rua && formErrors.rua}
                          helperText={touchedFields.rua && formErrors.rua ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Rua"
                          variant="standard"
                          name="rua"
                          value={formValues.rua}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                        <TextField
                          error={touchedFields.numero && formErrors.numero}
                          helperText={touchedFields.numero && formErrors.numero ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Número"
                          variant="standard"
                          name="numero"
                          value={formValues.numero}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            const numericValue = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              [name]: numericValue,
                            }));
                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              [name]: numericValue.trim() === "",
                            }));
                          }}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </div>

                      <div className="flex flex-col md:flex-row md:gap-4">
                        <TextField
                          error={touchedFields.complemento && formErrors.complemento}
                          helperText={touchedFields.complemento && formErrors.complemento ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Complemento"
                          variant="standard"
                          name="complemento"
                          value={formValues.complemento}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                        <TextField
                          error={touchedFields.bairro && formErrors.bairro}
                          helperText={touchedFields.bairro && formErrors.bairro ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Bairro"
                          variant="standard"
                          name="bairro"
                          value={formValues.bairro}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            )}
            {/* END: PASSO 2 */}

            {/* START: PASSO 3 */}
            {activeStep === 3 && (
              <div className="flex flex-grow flex-col gap-6">
                <div className="flex flex-col">
                  <b className="text-[20px]">DADOS DE PAGAMENTO</b>
                  <span className="font-normal">Para completar o seu pedido, preencha abaixo com os seus dados:</span>
                </div>

                <div className="flex flex-col md:flex-row gap-4">
                  <FormControl variant="standard" className="w-full mb-4">
                    <InputLabel id="demo-simple-select-standard-label">Escolha a data de vencimento</InputLabel>
                    <Select
                      className="mb-4"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={vencimento}
                      onChange={(e) => {
                        setVencimento(e.target.value);
                      }}
                    >
                      {vencimentos.map((vencimento) => (
                        <MenuItem key={vencimento.index} value={vencimento.index}>
                          {vencimento.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" className="w-full mb-4">
                    <InputLabel id="demo-simple-select-standard-label">Tipo de conta</InputLabel>
                    <Select
                      className="mb-4"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={tipoDeConta}
                      onChange={(e) => {
                        setTipoDeConta(e.target.value);
                      }}
                    >
                      {tiposConta.map((tipo) => (
                        <MenuItem key={tipo.index} value={tipo.index}>
                          {tipo.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="flex items-center">
                  <FormGroup className="mr-[-20px]">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={pagamentoDebitoAutomatico}
                          onChange={() => {
                            setPagamentoDebitoAutomatico(!pagamentoDebitoAutomatico);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="text-[#666666]">Pagamento via Débito Automático</span>
                </div>

                {pagamentoDebitoAutomatico && (
                  <div className="flex-col">
                    <FormControl variant="standard" className="w-full mb-4">
                      <InputLabel id="demo-simple-select-standard-label">Banco</InputLabel>
                      <Select
                        className="mb-4"
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={banco}
                        onChange={(e) => {
                          setBanco(e.target.value);
                        }}
                      >
                        {bancos.map((banco) => (
                          <MenuItem key={banco.index} value={banco.index}>
                            {banco.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box
                      component="form"
                      sx={{
                        width: "100%",
                        "& > :not(style)": { m: 0, width: "100%" },
                      }}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="flex gap-4 md:flex-row flex-col">
                        <TextField
                          error={touchedFields.agencia && formErrors.agencia}
                          helperText={touchedFields.agencia && formErrors.agencia ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Agência"
                          variant="standard"
                          name="agencia"
                          value={formValues.agencia}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            const numericValue = value.replace(/\D/g, ""); // Remove all non-numeric characters
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              [name]: numericValue,
                            }));
                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              [name]: numericValue?.trim() === "",
                            }));
                          }}
                          onBlur={handleBlur}
                          fullWidth
                        />
                        <TextField
                          error={touchedFields.conta && formErrors.conta}
                          helperText={touchedFields.conta && formErrors.conta ? "Obrigatório" : ""}
                          id="standard-basic"
                          label="Conta"
                          variant="standard"
                          name="conta"
                          value={formValues.conta}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            const numericValue = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              [name]: numericValue,
                            }));
                            setFormErrors((prevErrors) => ({
                              ...prevErrors,
                              [name]: numericValue.trim() === "",
                            }));
                          }}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </div>
                    </Box>
                  </div>
                )}

                <div className="flex items-center">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          name="concordo"
                          checked={formValues.concordo}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                  </FormGroup>
                  <span className="ml-[-20px] max-w-[550px] text-[#666666]">
                    Concordo com as Cláusulas de contrato de fidelidade de plano, Declaro que conheço e concordo com as
                    Cláusulas do contrato de prestação de serviço móvel pessoal controle e estou de acordo com o
                    regulamento da oferta.
                  </span>
                </div>
                {formErrors.concordo && <Typography color="error">Este campo é obrigatório.</Typography>}
                <div className="flex flex-col gap-3">
                  <a
                    className="regulamento"
                    href="https://timbrasil.widen.net/content/sibuo9uunm/pdf/regulamento-parceria-tim-e-c6-bank.pdf"
                    target="_blank"
                  >
                    Regulamento Promoção benefícios parceira C6 Bank
                  </a>
                  <a
                    className="regulamento"
                    href="https://timbrasil.widen.net/content/ldyey6ljkr/pdf/regulamento-tim-black-empresa-lll.pdf"
                    target="_blank"
                  >
                    Regulamento Tim Black Empresa III Setembro 2024
                  </a>
                </div>
              </div>
            )}
            {/* END: PASSO 3 */}

            {/* START: SHOW THANKS */}
            {activeStep === 100 && (
              <div className="flex flex-grow flex-col gap-6">
                <div className="flex flex-col">
                  <b className="text-[20px]">Parabéns</b>
                  <span className="font-normal">Seus dados foram enviados com sucesso!!</span>

                  <button
                    className="w-full md:w-[30%] rounded-[8px] py-2 text-[17px] font-normal bg-[#002198] text-white mt-5"
                    onClick={() => {window.location.href = '/';}}
                  >
                    Ir para Tela de Início
                  </button>
                </div>
              </div>
            )}
            {/* END: SHOW THANKS */}

            {/* START: BOTÕES */}
            {activeStep !== 100 && (
              <div className="flex flex-col md:flex-row gap-4">
                <button
                  className="w-full md:w-[30%] rounded-[8px] py-2 text-[14px] font-normal bg-[#A7A7A7]"
                  onClick={() => back()}
                >
                  {activeStep !== 1 ? "Voltar" : "Fechar"}
                </button>
                <button
                  style={{
                    backgroundColor: `${nextStepAvailable() ? "#1532A1" : "#DDDDDD"}`,
                    color: `${nextStepAvailable() ? "white" : "#3C3C3C"}`,
                  }}
                  className="w-full md:w-[40%] rounded-[8px] py-2 text-[14px] font-normal"
                  disabled={!nextStepAvailable()}
                  onClick={() => nextStep()}
                >
                  Continuar
                </button>
              </div>
            )}
            {/* END: BOTÕES */}
          </div>
        )}
        <div className="flex md:w-[30%]">
          <CadastroPlanoInfo
            bgColor="#0D3D57"
            type={tipoPlano}
            planoEsim={planoEsim}
            info={planoInfo}
            getInfo={getInfo}
            hidePlanoInfo={hidePlanoInfo}
          />
        </div>
      </section>
      <Footer minWidth="md:min-w-[95%]" />
    </div>
  );
};

export default Cadastro;
