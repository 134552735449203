import React from "react";
import BannerCol1 from "../../assets/TimBlack/BannerCol1.png";
import BannerCol1Mobile from "../../assets/TimBlack/BannerCol1Mobile.png";
import BannerCol2Mobile from "../../assets/TimBlack/BannerCol2Mobile.png";
import BannerCol3Mobile from "../../assets/TimBlack/BannerCol3Mobile.png";
import BannerCol2 from "../../assets/TimBlack/BannerCol2.png";
import BannerCol3 from "../../assets/TimBlack/BannerCol3.png";
import Botao from "../../assets/TimBlack/BotaoContratar.png"
import "./style.css";

const TimBlackBanner = () => {
  return (
    <div className="flex flex-col md:flex justify-center items-center min-h-[30%] pb-4 bg-[#002E46]">
      <div className="desktop w-[990px] flex flex-col md:flex-row justify-between items-center">
        <div>
          <img src={BannerCol1} width="320px" alt="logo" />
        </div>
        <div>
          <img src={BannerCol2} width="860px" alt="logo" />
        </div>
        <a href="/cadastro/blackclight">
          <img src={BannerCol3} width="240px" alt="logo" />
        </a>
      </div>
      {/* START: MOBILE */}
      <div className="mobile flex flex-col justify-between items-center">
        <div className="mb-[-100px] z-10">
          <img src={BannerCol1Mobile} width="430px" alt="logo" />
        </div>
        <div className="mb-[-130px]">
          <img src={BannerCol2Mobile} width="490px" alt="logo" />
        </div>
        <div>
         <img src={BannerCol3Mobile} width="400px" alt="logo" />
          <a href="/cadastro/blackclight">
            <img src={Botao} width="100%" height="10px" alt="logo" />
          </a>
        </div>
      </div>
      {/* END: MOBILE */}
    </div>
  );
};

export default TimBlackBanner;
