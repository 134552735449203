import { GoogleSpreadsheet } from 'google-spreadsheet';
import { KJUR } from 'jsrsasign' 




export async function AdicionaLinha(idPlanilha,creds,conteudo) {
var pHeader = {"alg":"RS256","typ":"JWT"}
var sHeader = JSON.stringify(pHeader);

var pClaim = {};
pClaim.aud = "https://www.googleapis.com/oauth2/v3/token";
pClaim.scope = "https://www.googleapis.com/auth/spreadsheets";
pClaim.iss = creds.mail;
pClaim.exp = KJUR.jws.IntDate.get("now + 1hour");
pClaim.iat = KJUR.jws.IntDate.get("now");
var sClaim = JSON.stringify(pClaim);

var key = creds.key;
 
var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);

var XHR = new XMLHttpRequest();
var urlEncodedData = "";
var urlEncodedDataPairs = [];

urlEncodedDataPairs.push(encodeURIComponent("grant_type") + '=' + encodeURIComponent("urn:ietf:params:oauth:grant-type:jwt-bearer"));
urlEncodedDataPairs.push(encodeURIComponent("assertion") + '=' + encodeURIComponent(sJWS));
urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

var token = ""
XHR.addEventListener('load', async function(event) {
    var response = JSON.parse(XHR.responseText);
    token = response["access_token"]
    const doc = new GoogleSpreadsheet(idPlanilha,  { token: token });
    await doc.loadInfo();
    const sheet = doc.sheetsByIndex[0];
    await sheet.addRow(conteudo)
});

XHR.addEventListener('error', function(event) {
    console.log('Oops! Something went wrong.');
});

XHR.open('POST', 'https://www.googleapis.com/oauth2/v3/token');
XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
XHR.send(urlEncodedData)
}