import React, { useState, useRef, useEffect } from "react";
import Logo from "../../assets/Global/revendedor-autorizado-tim.png";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import LogoNovaSVG from "../../assets/Global/LogoTimParceiro.svg";
import LogoNovaPNG from "../../assets/Global/LogoTimNova.png";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  width: "90%",
  maxWidth: 500,
  boxShadow: 24,
};

const Menu = ({ cor1, cor2 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [hideRegion, setHideRegion] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [open, setOpen] = useState(false);
  const [estado, setEstado] = useState("");
  const [ddd, setDDD] = useState("");
  const [sigla, setSigla] = useState("");
  const [showDDD, setShowDDD] = useState(false);
  const [verOfertaClicked, setVerOfertaClicked] = useState(false);

  const estados = [
    { index: 1, nome: "Acre", ddd: 68, sigla: "AC" },
    { index: 2, nome: "Alagoas", ddd: 82, sigla: "AL" },
    { index: 3, nome: "Amazonas", ddd: [92, 97], sigla: "AM" },
    { index: 4, nome: "Bahia", ddd: [71, 73, 74, 75, 77], sigla: "BA" },
    { index: 5, nome: "Ceará", ddd: [85, 88], sigla: "CE" },
    { index: 6, nome: "Distrito Federal", ddd: 61, sigla: "DF" },
    { index: 7, nome: "Espírito Santo", ddd: [27, 28], sigla: "ES" },
    { index: 8, nome: "Goiás", ddd: [62, 64], sigla: "GO" },
    { index: 9, nome: "Maranhão", ddd: [98, 99], sigla: "MA" },
    { index: 10, nome: "Minas Gerais", ddd: [31, 32, 33, 34, 35, 37, 38], sigla: "MG" },
    { index: 11, nome: "Mato Grosso do Sul", ddd: 67, sigla: "MS" },
    { index: 12, nome: "Mato Grosso", ddd: [65, 66], sigla: "MT" },
    { index: 13, nome: "Pará", ddd: [91, 93, 94], sigla: "PA" },
    { index: 14, nome: "Paraíba", ddd: 83, sigla: "PB" },
    { index: 15, nome: "Pernambuco", ddd: [81, 87], sigla: "PE" },
    { index: 16, nome: "Piauí", ddd: [86, 89], sigla: "PI" },
    { index: 17, nome: "Paraná", ddd: [41, 42, 43, 44, 45, 46], sigla: "PR" },
    { index: 18, nome: "Rio de Janeiro", ddd: [21, 22, 24], sigla: "RJ" },
    { index: 19, nome: "Rio Grande do Norte", ddd: 84, sigla: "RN" },
    { index: 20, nome: "Rondônia", ddd: 69, sigla: "RO" },
    { index: 21, nome: "Rio Grande do Sul", ddd: [51, 53, 54, 55], sigla: "RS" },
    { index: 22, nome: "Santa Catarina", ddd: [47, 48, 49], sigla: "SC" },
    { index: 23, nome: "Sergipe", ddd: 79, sigla: "SE" },
    { index: 24, nome: "São Paulo", ddd: [11, 12, 13, 14, 15, 16, 17, 18, 19], sigla: "SP" },
    { index: 25, nome: "Tocantins", ddd: 63, sigla: "TO" },
  ];
  

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    console.log(event.target);
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setHideRegion(true);
    } else if (window.scrollY === 0) {
      setHideRegion(false);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeEstado = (event) => {
    setShowDDD(false);
    setEstado(event.target.value);
    const selectedEstado = estados.find((e) => e.index === event.target.value);

    if (Array.isArray(selectedEstado.ddd)) {
      setDDD("");
      setSigla(selectedEstado.sigla);
    } else {
      setDDD(String(selectedEstado.ddd));
      setSigla(selectedEstado.sigla);
      setShowDDD(true);
      console.log("adicionou", selectedEstado.ddd);
    }
  };

  const handleChangeDDD = (event) => {
    console.log("aqui", event.target.value);
    setSigla(selectedEstado.sigla);
    setDDD(String(event.target.value));
  };

  const verOfertas = () => {
    setIsOpen(false);
    setOpen(false);
    setVerOfertaClicked(true);
  };

  const selectedEstado = estados.find((e) => e.index === estado);
  const ddds = selectedEstado && Array.isArray(selectedEstado.ddd) ? selectedEstado.ddd : [selectedEstado?.ddd];

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full modal-header flex justify-between items-center py-3 px-4 bg-[#072198] text-white">
            <span className="font-medium">Escolha sua Região</span>
            <AiOutlineClose className="cursor-pointer" onClick={handleClose} />
          </div>
          <div className=" flex flex-col px-10 pt-10 pb-3">
            <span className="font-light mb-4">
              Escolha a região que deseja adiquirir seu plano e traremos as melhores ofertas para você!
            </span>
            <div className="w-full flex flex-col justify-center pb-5">
              <FormControl variant="standard" className="w-full mb-4">
                <Select
                  className="mb-4"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={estado}
                  onChange={handleChangeEstado}
                >
                  {estados.map((estado) => (
                    <MenuItem key={estado.index} value={estado.index}>
                      {estado.nome}
                    </MenuItem>
                  ))}
                </Select>
                {ddds.length > 1 && (
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={ddd}
                    onChange={handleChangeDDD}
                  >
                    {ddds.map((ddd) => (
                      <MenuItem key={ddd} value={ddd}>
                        {ddd}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              {ddd.length >= 1 && showDDD && (
                <div className="text-[14px] flex w-full justify-center p-1 border-2 border-[#1532A1] mt-5 text-[#1532A1] rounded-full">
                  <span>DDD {ddd}</span>
                </div>
              )}
            </div>
            <button
              onClick={() => verOfertas()}
              className={`${
                ddd.length >= 1
                  ? "bg-[#002198] hover:bg-[#1532A1] text-white cursor-pointer"
                  : "bg-[#E0E0E0] text-[#ABABAB] cursor-default"
              }  py-2 rounded-[6px] text-[13px] font-medium`}
            >
              Ver ofertas
            </button>
          </div>
        </Box>
      </Modal>

      <section //verifica se é tela cadastro
        className={`flex flex-row w-full text-white justify-center ${
          cor1 !== "#0D3D57" ? "sticky" : ""
        } top-0 z-50 text-[16px]`}
        style={{ backgroundColor: hideRegion ? cor1 : cor2 }}
        ref={sidebarRef}
      >
        {/* START: SIDEBAR MENU */}
        <div className="sidebar-container" style={{ backgroundColor: cor1 }}>
          <div className={`sidebar ${isOpen ? "open" : ""}`}>
            <ul className="menu-items flex flex-col gap-4">
              <div className="p-4 mb-2">
                <img src={LogoNovaSVG} width={75} alt="logo" />
              </div>
              <li>
                <a href="/controle">TIM Empresas</a>
              </li>
            </ul>
          </div>
        </div>
        {/* END: SIDEBAR MENU */}
        {/* START: MENU TOPBAR */}
        <div
          className={`menu-celular md:min-w-[990px] flex ${
            hideRegion ? "py-2" : "py-4"
          } md:px-1 px-6 gap-8 items-center`}
        >
          {isMobile ? (
            <>
              {!hideRegion ? (
                <div className="flex items-center gap-4">
                  <div>
                    <AiOutlineMenu className="hamburger-button" onClick={toggleSidebar} />
                  </div>
                  <img src={LogoNovaSVG} width={85} alt="logo" />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="flex items-center gap-3">
              <div>
                <AiOutlineMenu className="hamburger-button" onClick={toggleSidebar} />
              </div>
              <img src={LogoNovaSVG} width={100} alt="logo" />
            </div>
          )}
          <div
            className={`flex ${hideRegion ? "md:justify-end justify-end" : "md:justify-between justify-end"} flex-grow`}
          >
            {cor2 != "#002198" && (
              <ul className={`${hideRegion ? "" : "menu-hide"} menu-items flex gap-5`}>
                <li>
                  <a href="/">TIM Empresas</a>
                </li>
              </ul>
            )}
            {!hideRegion && (
              <ul className="menu-items flex gap-5 tracking-[1px] text-[14px]">
                {!verOfertaClicked || ddd === "" || sigla === "" ? (
                  <li className="font-medium" onClick={handleOpen}>
                    Escolha sua região
                  </li>
                ) : (
                  <button onClick={handleOpen}>{sigla} | {ddd}</button>
                )}
                {/* <li className="font-light menu-hide">
                  <a href="tel:81996716091">Televendas: (81) 9.9671-6091</a>
                </li> */}
              </ul>
            )}
          </div>
        </div>
        {/* END: MENU TOPBAR */}
      </section>
    </>
  );
};

export default Menu;
