import React, { useState, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { AiOutlineClose } from "react-icons/ai";

import Box from "@mui/material/Box";
import "./style.css";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    width: "90%",
    maxWidth: 500,
    boxShadow: 24,
  };

const Menu = ({ header, open, handleClose, children }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-full modal-header flex justify-between items-center py-3 px-4 bg-[#072198] text-white">
            <span className="font-medium">{header}</span>
            <AiOutlineClose className="cursor-pointer" onClick={handleClose} />
          </div>
          <div className=" flex flex-col px-10 pt-5 pb-3">
            <div className="w-full flex flex-col justify-center pb-5">
                {children}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Menu;
