import { useEffect, useState } from "react";
import Celular from "../../assets/TimBlack/image-cell.png";
import "./style.css";

const AbraUmaContaC6 = () => {
  return (
    <section className="bg-[#1F1E1E] md:pt-20 md:py-0 py-10 w-full justify-center flex text-white">
      <div className="md:max-w-[990px] md:px-0 px-4 md:px-0">
        <div className="flex flex-col-reverse md:flex-row justify-between px-3 md:px-0">
          <div className="flex flex-col md:max-w-[50%] gap-3">
            <span className="text-center md:text-start">C6 Bank: um banco digital completo</span>
            <span className="text-[26px] md:text-[32px] md:leading-[44px] leading-[30px] text-center md:text-start">
              Abra uma conta no C6 Bank e escolha ter tudo
            </span>
            <span className="text-[14px] mt-6 md:w-[70%] md:text-start text-center">
              Tenha bônus de internet, parcelamento de smartphone em 21x, 6 meses de anuidade grátis no cartão C6 Carbon
              e muito mais. Sujeito a análise de crédito.
            </span>
          </div>

          <div className="md:w-[50%] flex justify-center">
            <img src={Celular} className="md:w-[100%] w-[50%]" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AbraUmaContaC6;
